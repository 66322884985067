import {  Component, EventEmitter, Input, Output, ViewChild, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MileageDetailComponent } from "src/app/shared/panels/mileage-detail/mileage-detail.component";
import { ViewReceiptComponent } from "src/app/shared/panels/view-receipt/view-receipt.component";
import { ApiRequest, IApiRequest, ReceiptEnum } from "src/app/_models";
import { ExpenseCurrency, PolicyViolationInputModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { IReceiptInfo, IRequestPolicyViolation, PolicyViolation, RequestPolicyViolation } from "src/app/_models/twostepapproval/claimant-expense-detail";
import { CurrencyService } from "src/app/_services/claimant/currency.service";
import { PolicyDataService } from "src/app/_services/policy/policy-data.service";
import { Claims } from "src/app/_models/common/claims";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { ColDef } from "ag-grid-community";
import { GridConfig } from "src/app/_models/common/grid/grid-config";
import { AppGridComponent } from "src/app/shared/grid/grid-component/grid.component";
import { ActivatedRoute } from "@angular/router";
import { NrFcLogicsService } from "src/app/_services/NrFc/nr-fc-logics.service";

@Component({
  selector: 'app-generic-policy-violation-panel',
  templateUrl: './generic-policy-violation-panel.component.html'
})
export class GenericPolicyViolationPanelComponent {
    @Input() expenseDetails: any;
  @Input() inputData: PolicyViolationInputModel;
  @Input() selectedPolicySubCategoryItem: PolicyViolation;  //PolicyViolation
    @Input() isReceiptNumberSettingsEnabled:boolean;
    @Input()
    zindex_Class: string;
    @Output() closePanelEvent = new EventEmitter<string>();
    @Output() ischangeJustifactionNotes = new EventEmitter<any>();
    showPotentialFraud: boolean = false;
    param: any;
    getPolicyRequest: IApiRequest<IRequestPolicyViolation> = new ApiRequest<RequestPolicyViolation>("", 1, "");
    getPolicyResponse: any = {};
    isPolicyViolated: boolean = false;
    dailyCapVisible: boolean = false;
    monthlyCapVisible: boolean = false;
    yearlyCapVisible: boolean = false;
    duplicateVisible: boolean = false;
    vatNoVatVisible: boolean = false;
    overallVisible: boolean = false;
    perNightVisible: boolean = false;
    perItemVisible: boolean = false;
    expenseTimeLimitVisible: boolean = false;
    defaultVehicleTypeVisible:boolean=false;
    operationalInformationVisible:boolean=false;
    operationalInformationViolation: any;
    showPolicyViolation: boolean = false;
    currencytList: ExpenseCurrency[];
    @ViewChild('viewReceiptComponent') viewReceiptComponent: ViewReceiptComponent;
    receiptInfo: any;
    receiptCount: number = 0;
    hasReturnJourney: boolean = false;
    milesTravelled: any;
    mileageLabel: string;
    detourMiles: any;
    triangulationMiles: any;
    mileageRate: string;
    distance: any;
    origin: any;
    destination: any;
    receiptLat: number;
    receiptLong: number;
    latLongArray: any;
    currency: string;
    openSectionName: string;
    isNoteError: boolean = false;
    hasFormChanges: boolean = false;
    zindexClass:string="";
    claims: Claims;
    @ViewChild('mileageDetailComponent') mileageDetailComponent: MileageDetailComponent;
    gridColumnDefs: ColDef[];
    gridConfigItems: GridConfig
    gridData: any[] = [];
    @ViewChild(AppGridComponent) gridComponent: AppGridComponent;
    pageType: string;
    expenseList: any[];
    duplicateReceiptInfo: IReceiptInfo[];
    showDuplicateReceiptPanel: boolean = false;
    constructor(
        private translate: TranslateService,
        private policyDataService: PolicyDataService,
        private currencyService: CurrencyService,
        private claimsService: ClaimsService,
        private cd:ChangeDetectorRef,
        private route: ActivatedRoute,
        private nrFcLogicsService: NrFcLogicsService
    ) {
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
        this.route.queryParams.subscribe(params => {
            this.pageType = this.nrFcLogicsService.DecryptParameters(params['page']);
          });
    }

    ngOnInit() {
        this.fnInitialiseGrid();
        if(this.zindex_Class)
              this.zindexClass = this.zindex_Class;
        this.getCurrrencyList();
        if(this.selectedPolicySubCategoryItem)
            {
                this.setPolicViolationList(this.inputData.expenseId);
            }
        
        this.fnGetPotentialFraudList();
    }

    fnClosePanel() {
        this.closePanelEvent.emit(this.inputData.justificationNotes);
    }

    fnRefreshData(){
      this.setPolicViolationList(this.inputData.expenseId);
    }

    setPolicViolationList(expenseId: number) {
      if (this.inputData) {
          this.getPolicyResponse.policyViolation = this.selectedPolicySubCategoryItem;
          if (this.getPolicyResponse.policyViolation.dailyCappingLimit != null)
              this.dailyCapVisible = true;
          if (this.getPolicyResponse.policyViolation.duplicate!=null && this.getPolicyResponse.policyViolation.duplicate.length > 0) {
              if ((this.inputData.baseCategoryId == 4 ||
                  this.inputData.baseCategoryId == 5 ||
                  this.inputData.baseCategoryId == 6) &&
                  this.getPolicyResponse.policyViolation.duplicate[0].receiptInfo.length > 0) {
                  this.receiptInfo = this.getPolicyResponse.policyViolation.duplicate[0].receiptInfo;
                  if (this.receiptInfo) {
                      this.receiptCount = this.receiptInfo.length;
                  }
              }
              this.duplicateVisible = true;
          }
          let operationalInfoViolation = this.getPolicyResponse.policyViolation.expenseOperationalInfo;
          this.operationalInformationViolation = operationalInfoViolation != null && operationalInfoViolation.length != 0 ? this.getPolicyResponse.policyViolation.expenseOperationalInfo.filter(it => it.fieldValue == false) : [];
    
          if (this.operationalInformationViolation.length != 0) {
              this.operationalInformationVisible=true;                            
          }
          if (this.getPolicyResponse.policyViolation.expenseTimeLimit != null)
              this.expenseTimeLimitVisible = true;
          if (this.getPolicyResponse.policyViolation.monthlyCappingLimit != null)
              this.monthlyCapVisible = true;
          if (this.getPolicyResponse.policyViolation.overallDailyLimit != null)
              this.overallVisible = true;
          if (this.getPolicyResponse.policyViolation.perNightLimit != null)
              this.perNightVisible = true;
          if (this.getPolicyResponse.policyViolation.perPersonLimit != null)
              this.perItemVisible = true;
          if (this.getPolicyResponse.policyViolation.vatNovatReceiptType != null)
              this.vatNoVatVisible = true;
          if (this.getPolicyResponse.policyViolation.yearlyCappingLimit != null)
              this.yearlyCapVisible = true;
          if(this.getPolicyResponse.policyViolation.defaultVehicleType!=null){
              this.defaultVehicleTypeVisible=true;
              }
          this.fnGetOpenSectionName();
      }
      this.cd.detectChanges();

    }

    getCurrrencyList() {
        this.currencyService.getAllExpenseCurrency(this.claims.OrgId)
            .subscribe((data: any) => {
                if (data && data.length > 0) {
                    this.currencytList = data;
                }
            });
    }

    getCurrencyName(currencyId: number) {
        if (this.currencytList) {
            return this.currencytList.filter(a => a.currencyId == currencyId).map(function (b) { return b.currencyCode })[0];
        }
    }

    fnShowReceiptsPanel(items: IReceiptInfo[]) {
        this.receiptInfo = items;
        if (this.receiptInfo) {
            this.receiptCount = this.receiptInfo.length;
        }
        this.viewReceiptComponent.fnShowReceiptsPanel(items);
    }

    fnShowmileageMapDetail() {
        this.mileageDetailComponent.fnMapData(true, 0, this.inputData.expenseDetailResponse);
    }

    fnSubmitPanel() {
        if (this.inputData.justificationNotes.trim()) {
            this.closePanelEvent.emit(this.inputData.justificationNotes.trim());
        } else {
            this.isNoteError = true;
        }
    }
    fnGetOpenSectionName() {
        if (this.expenseTimeLimitVisible) {
            this.openSectionName = "expenseTimeLimitVisible";
            return;
        } else if (this.vatNoVatVisible) {
            this.openSectionName = "vatNoVatVisible";
            return;
        } else if (this.duplicateVisible) {
            this.openSectionName = "duplicateVisible";
            return;
        } else if (this.overallVisible) {
            this.openSectionName = "overallVisible";
            return;
        } else if (this.dailyCapVisible) {
            this.openSectionName = "dailyCapVisible";
            return;
        } else if (this.monthlyCapVisible) {
            this.openSectionName = "monthlyCapVisible";
            return;
        }
        else if (this.yearlyCapVisible) {
            this.openSectionName = "yearlyCapVisible";
            return;
        } else if (this.perItemVisible) {
            this.openSectionName = "perItemVisible";
            return;
        } else if (this.perNightVisible) {
            this.openSectionName = "perNightVisible";
            return;
        }
        else if (this.defaultVehicleTypeVisible) {
            this.openSectionName = "defaultVehicleTypeVisible";
            return;
        }

        if (this.operationalInformationViolation.length != 0) {
            this.openSectionName = 'operationalInformationVisible';
            for (var i = 0; i < this.operationalInformationViolation.length; i++) {
              this.operationalInformationViolation[i].fieldValueString = "NO";
            }
          }
          
    }

    onChangeJustificationNotes(){
        this.hasFormChanges= true;
        this.ischangeJustifactionNotes.emit(this.inputData.justificationNotes);
    }

    fnInitialiseGrid() {
        this.fnCreateColumnDefs();
        this.fnGetGridConfigItems();
        setTimeout(() => {
          this.gridComponent.initializeGrid();
        }, 100);
      }
    
      fnCreateColumnDefs() {
        this.gridColumnDefs = [
          {
              headerName: this.translate.instant('label_claimant'),          
              suppressMenu: true,
              minWidth: 100,
              field: 'displayName',
              resizable: true,
              sortable: true
          },
          {
            headerName: this.translate.instant('claimant_email'),          
            suppressMenu: true,
            minWidth: 100,
            field: 'email',
            resizable: true,
            sortable: true
          },
          {
              headerName: this.translate.instant('cc_dashboard_label_date'),                  
              minWidth: 100,
              field: 'expenseDate',
              cellRenderer:'dateRenderer',  
              resizable: true,
              sortable: true,
              suppressMenu: true          
          },
          {
            headerName: this.translate.instant('label_amount'),                
            suppressMenu: true, 
            field: 'expenseAmount',
            resizable: true,
            sortable: true,          
            headerClass: 'text-right',
            cellClass: 'text-right',
            cellRenderer: 'amountRenderer'    
          },
          {
            headerName: this.translate.instant('Supplier'),
            field: 'supplierName',
            resizable: true,
            sortable: true,
            suppressMenu: true,          
            minWidth: 100      
          },
          {
            headerName: this.translate.instant('invoice_receipt'),
            headerComponentParams: [
              { class: 'text-underline text-logo-blue', value: ReceiptEnum.View, text: this.translate.instant("view") },
              { class: 'no-receipt', value: ReceiptEnum.NoReceipt, text: this.translate.instant("no_receipt") }
            ],
            field: 'receipt',                
            minWidth: 100,
            resizable: true,
            sortable: true,
            suppressMenu: true,  
            cellRenderer: 'fieldValueRenderer'
            
          }   
        ];  
      }
    
      fnGetGridConfigItems() {
          this.gridConfigItems = new GridConfig(
            true,
            false,
            "displayName",
            "expenseId",
            0,
            false,
            [],
            true,
            true,
            false,
            null,
            'asc',
            40, 'no_data_availabe', null, null,
            false,false,null,null,false
          );
        }
    
      fnGetPotentialFraudList() {
    
        if((this.pageType == 'finance') && this.expenseDetails.potentialFraud){
    
            this.showPotentialFraud = true;
            this.openSectionName = "potentialFraud";
            this.policyDataService.GetPotentialFraudData(this.inputData.expenseId)
            .subscribe(response => {
                  if (response.statusCode == 0) {
    
                      this.expenseList = response.responseInfo.lstExpenses;
    
                      if(this.expenseList){
                        
                        this.expenseList.forEach(element => {
                          if(element.receiptInfo.length>0)
                            element.receipt = 1;
                          else
                            element.receipt = 0;
                        });
                        
                        this.fnGridFilterChanged();
    
                      }
                  }
              });
          }
      }

    fnGridFilterChanged() {
        this.gridData = this.expenseList;
    
        this.fnGetGridConfigItems();
    
        setTimeout(() => {
            this.gridComponent.refreshGridData();
        }, 100);
    }
    
      fnOnGridCellClick(params) {
        if(params.column.colId == 'receipt'){
          this.fnShowDuplicateReceiptPanel(params.data.receiptInfo);
        }         
      }
      fnShowDuplicateReceiptPanel(duplicateReceipt) {
        this.duplicateReceiptInfo = duplicateReceipt;  
        this.originalReceiptInfo = this.expenseDetails.receipts;
        this.showDuplicateReceiptPanel = true;
      }
      claimType = 1;
      originalReceiptInfo: IReceiptInfo[] = [];
      fnCloseDuplicateReceiptPanel(isClose){
        this.showDuplicateReceiptPanel = false;
     }

}
