import { Injectable } from "@angular/core";
import { Claims } from "../../../../src/app/_models/common/claims";
import { BehaviorSubject } from "rxjs";
import { FunctionPackageEnum, Page } from "src/app/_models";

@Injectable({
  providedIn: "root",
})
export class ClaimsService {
  claims: Claims = null;
  private _claims = new BehaviorSubject(this.claims);
  currentClaims = this._claims.asObservable();

  constructor() {}
  updateClaims(newClaims: Claims) {
    this._claims.next(newClaims);

    if (newClaims) {
      localStorage.setItem("claims", JSON.stringify(newClaims));
    } else {
      localStorage.removeItem("claims");
    }
  }

  public selectedPackageCategory: number = 1;
  private _selectedPackageCategory = new BehaviorSubject(
    this.selectedPackageCategory
  );
  currentSelectedPackageCategory = this._selectedPackageCategory.asObservable();

  updateSelectedPackageCategory(newValue: number) {
    this._selectedPackageCategory.next(newValue);
  }

  SetFunctionPackageClaims(
    functionPurchasedTrailList: any,
    existingClaims: Claims,
    functionCalledFrom: number
  ) {
    for (let index = 0; index < functionPurchasedTrailList.length; index++) {
      let element = functionPurchasedTrailList[index];

      let functionId = 0;
      switch (functionCalledFrom) {
        case Page.Home:
          functionId = element.functionId;
          break;
        case Page.ShoppingCartConfirm:
          functionId = element.functionPackageId;
          break;
      }

      existingClaims = this.SetValueToFunctionPackageClaims(
        functionId,
        true,
        existingClaims
      );
    }

    return existingClaims;
  }

  SetValueToFunctionPackageClaims(
    functionId: number,
    value: boolean,
    existingClaims: Claims
  ) {
    switch (functionId) {
      case FunctionPackageEnum.AccountingIntegration:
        existingClaims.IsAccountingIntegrationPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.SetupCreditCard:
        existingClaims.IsCreditCardPackageSubscribed = value;
        break;
      case FunctionPackageEnum.ExpenseCategoryManagement:
        existingClaims.IsExpenseCategoryPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.SetupCostCentre_Assign_toClaimants:
        existingClaims.IsCostCentrePackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.Customer:
        existingClaims.IsCustomerPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.ExportReport:
        existingClaims.IsExportReportPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.SetupTaxRate:
        existingClaims.IsTaxPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.ProjectBudget:
        existingClaims.IsProjectBudgetPurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.SetupMultipleCompanies:
        existingClaims.IsMultipleCompaniesPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.NonReimbursableFuel:
        existingClaims.isNRPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.FuelCard:
        existingClaims.isFCPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.Location:
        existingClaims.IsLocationPurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.ConfigureAdministratorPrivilege:
        existingClaims.IsSecondaryFMPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.AssignDeputyToApprovers:
        existingClaims.IsAssignDeputyToApproversPurchased = value;
        break;
      case FunctionPackageEnum.Supplier:
        existingClaims.IsSupplierPurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.DutyofCare:
        existingClaims.IsDocPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.MultipleCountry:
        existingClaims.IsMultipleCountryPackagePurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.DuplicateChecker:
        existingClaims.IsDuplicateCheckerPurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.Delegate:
        existingClaims.IsDelegateFunctionPurchasedOrTrial = value;
        if (!value) {
          existingClaims.IsDelegator = false;
          existingClaims.IsDelegate = false;
        }
        break;
      case FunctionPackageEnum.AttendeeType:
        existingClaims.IsAttendeeTypePurchased = value;
        break;
      case FunctionPackageEnum.APAutomation:
        existingClaims.isAPAutomationFunctionPurchasedOrTrial = value;
        break;
      case FunctionPackageEnum.RecallExportedExpenses:
        existingClaims.IsRecallExportedExpensesPurchased = value;
            break;
        case FunctionPackageEnum.SpecialCategory:
            existingClaims.IsSpecialCategoryFunctionPurchasedorTrial = value;
            break;
    }

    return existingClaims;
  }

  onToggleMobileMenu(IsLeftMenuCollapseAndStopToggle: boolean) {
    if (!IsLeftMenuCollapseAndStopToggle) {
      document.body.classList.toggle("sidebar-enable");
      const currentSIdebarSize =
        document.body.getAttribute("data-sidebar-size");
      if (window.screen.width >= 992) {
        if (currentSIdebarSize == null) {
          document.body.getAttribute("data-sidebar-size") == null ||
          document.body.getAttribute("data-sidebar-size") == "lg"
            ? document.body.setAttribute("data-sidebar-size", "sm")
            : document.body.setAttribute("data-sidebar-size", "lg");
        } else if (currentSIdebarSize == "md") {
          document.body.getAttribute("data-sidebar-size") == "md"
            ? document.body.setAttribute("data-sidebar-size", "sm")
            : document.body.setAttribute("data-sidebar-size", "md");
        } else {
          document.body.getAttribute("data-sidebar-size") == "sm"
            ? document.body.setAttribute("data-sidebar-size", "lg")
            : document.body.setAttribute("data-sidebar-size", "sm");
        }
      }
    } else {
      document.body.setAttribute("data-sidebar-size", "sm");
    }
  }
}
