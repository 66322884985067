import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { OAuthService } from "angular-oauth2-oidc";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { first } from "rxjs/operators";
import { Claims } from "src/app/_models/common/claims";
import {
  CurrencyInfo,
  MileageInfo,
  OrganisationBasicSetup,
  OrganisationBasicSetupRequestInfo,
  Profileinfo,
  SetupInfo,
} from "src/app/_models/user/userrequestdto";
import { AuthenticationService } from "src/app/_services";
import { ClaimsService } from "src/app/_services/common/claims.service";
import { VersionHistoryService } from "src/app/_services/common/version-history.service";
import { EMAIL_REGEX } from "src/util/constants";

@Component({
  selector: "app-invite-employees",
  templateUrl: "./invite-employees.component.html",
  styleUrls: ["./invite-employees.component.scss"],
})
export class InviteEmployeesComponent implements OnInit {
  @Output() gotoNextStepEvent = new EventEmitter<any>();

  newAttribute: any = {};
  newAttribute1: any = {};
  newAttribute2: any = {};
  hasValidData = true;
  fieldArray: Array<any> = [];
  claims: Claims;
  organisationBasicSetup: OrganisationBasicSetup;
  approverName: string;
  submitted = false;

  IsDuplicate: any;
  IsDuplicateGlobal: any;
  IsInValidEmailGlobal: any;
  IsMandatoryFieldMissing: boolean = false;
  display = "none";
  emailPattern = EMAIL_REGEX;
  IsInviteCanBeDisabled = false;
  constructor(
    private claimsService: ClaimsService,
    private ngxService: NgxUiLoaderService,
    private oauthService: OAuthService,
    private versionHistoryService: VersionHistoryService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
  ) {
    this.organisationBasicSetup = new OrganisationBasicSetup();
    this.claimsService.currentClaims.subscribe(
      (claims) => (this.claims = claims)
    );
  }

  ngOnInit(): void {
    this.bindVersionHistory();
  }

  gotoNextStep() {
    this.gotoNextStepEvent.emit();
  }

  validateInviteBeforeSubmit(inviteObject) {
    if (Object.keys(inviteObject).length === 0) {
      return false;
    }
    if (
      inviteObject.firstName != undefined &&
      inviteObject.firstName.trim() != "" &&
      inviteObject.email != undefined &&
      inviteObject.email != "" &&
      !inviteObject.isInvalidEmail &&
      !inviteObject.isUserBelongsToOtherOrganisation &&
      !inviteObject.isSelfInvite &&
      !inviteObject.IsDuplicate
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateAllInviteBeforeSubmit() {
    if (!this.validateInviteBeforeSubmit(this.newAttribute1)) {
      return false;
    }
    if (!this.validateInviteBeforeSubmit(this.newAttribute2)) {
      return false;
    }
    if (!this.validateInviteBeforeSubmit(this.newAttribute)) {
      return false;
    }

    for (var i = 0; i < this.fieldArray.length; i++) {
      if (!this.validateInviteBeforeSubmit(this.fieldArray[i])) {
        return false;
      }
    }

    return true;
  }

  OnInviteClick() {
    this.submitted = true;
    this.hasValidData = this.validateAllInviteBeforeSubmit();
    if (!this.hasValidData) {
      return;
    }
    this.IsInviteCanBeDisabled = true;
    this.ngxService.start();
    this.organisationBasicSetup.RequestId = "111";
    this.organisationBasicSetup.WebApiName = "OrganisationBasicSetup";
    this.organisationBasicSetup.WebApiVersion = 1;
    this.organisationBasicSetup.RequestInfo =
      new OrganisationBasicSetupRequestInfo();
    this.organisationBasicSetup.RequestInfo.languageCode = "en-US";
    this.organisationBasicSetup.RequestInfo.loginId = this.claims.LoginId;
    this.organisationBasicSetup.RequestInfo.platform = 0;
    this.organisationBasicSetup.RequestInfo.userId =
      this.claims.UserId.toString();
    this.organisationBasicSetup.RequestInfo.setUpInfo = new SetupInfo();
    this.organisationBasicSetup.RequestInfo.setUpInfo.currencyInfo =
      new CurrencyInfo();
    this.organisationBasicSetup.RequestInfo.setUpInfo.currencyInfo.countryId =
      this.claims.SelectedCountry;
    this.organisationBasicSetup.RequestInfo.setUpInfo.currencyInfo.countryName =
      this.claims.SelectedCountryName;
    this.organisationBasicSetup.RequestInfo.setUpInfo.currencyInfo.currencyCode =
      this.claims.SelectedCurrencyCode;
    this.organisationBasicSetup.RequestInfo.setUpInfo.currencyInfo.currencyName =
      this.claims.SelectedCurrencyName;
    this.organisationBasicSetup.RequestInfo.setUpInfo.currencyInfo.currencyId =
      Number(this.claims.SelectedCurrency);
    this.organisationBasicSetup.RequestInfo.setUpInfo.inviteInfo = new Array();

    this.organisationBasicSetup.RequestInfo.setUpInfo.inviteInfo.push({
      firstName: this.newAttribute1.firstName,
      lastName:
        typeof this.newAttribute1.lastName === undefined
          ? ""
          : this.newAttribute1.lastName,
      email: this.newAttribute1.email,
      roleId: 2,
      vehicleType: 1,
    });

    this.organisationBasicSetup.RequestInfo.setUpInfo.inviteInfo.push({
      firstName: this.newAttribute2.firstName,
      lastName:
        typeof this.newAttribute2.lastName === undefined
          ? ""
          : this.newAttribute2.lastName,
      email: this.newAttribute2.email,
      roleId: 1,
      vehicleType: 1,
    });

    for (let i = 0; i < this.fieldArray.length; i++) {
      this.organisationBasicSetup.RequestInfo.setUpInfo.inviteInfo.push({
        firstName: this.fieldArray[i].firstName,
        lastName:
          typeof this.fieldArray[i].lastName === undefined
            ? ""
            : this.fieldArray[i].lastName,
        email: this.fieldArray[i].email,
        roleId: 1, //i == 0 ? 2 : 1
        vehicleType: 1,
      });
    }
    this.approverName =
      this.newAttribute1.firstName +
      " " +
      (typeof this.newAttribute1.lastName ? "" : this.newAttribute1.lastName);
    this.organisationBasicSetup.RequestInfo.setUpInfo.inviteInfo.push({
      firstName: this.newAttribute.firstName,
      lastName:
        typeof this.newAttribute.lastName === undefined
          ? ""
          : this.newAttribute.lastName,
      email: this.newAttribute.email,
      roleId: 1,
      vehicleType: 1,
    });

    this.claims.EmployeesInvited = this.fieldArray.length + 3;
    this.claims.ApproverName = this.approverName;

    this.organisationBasicSetup.RequestInfo.setUpInfo.mileageInfo =
      new MileageInfo();
    this.organisationBasicSetup.RequestInfo.setUpInfo.mileageInfo.mileageRate = 0.45;
    this.organisationBasicSetup.RequestInfo.setUpInfo.mileageInfo.mileageRateFor =
      this.translate.instant('label_four_wheeler');
    this.organisationBasicSetup.RequestInfo.setUpInfo.mileageInfo.fMVehicleType = 1;
    this.organisationBasicSetup.RequestInfo.setUpInfo.mileageInfo.mileageUOM = 1;
    this.organisationBasicSetup.RequestInfo.setUpInfo.mileageInfo.mileageRateFor4Wheeler = 0.45;
    this.organisationBasicSetup.RequestInfo.setUpInfo.mileageInfo.mileageRateFor2Wheeler = 0.25;
    this.organisationBasicSetup.RequestInfo.setUpInfo.profileInfo =
      new Profileinfo();
    this.organisationBasicSetup.RequestInfo.setUpInfo.profileInfo.organisationName =
      this.claims.OrgName;
    this.claims.OrganisationBasicSetup = JSON.stringify(
      this.organisationBasicSetup
    );
    this.claimsService.updateClaims(this.claims);

    this.authenticationService
      .organisationBasicSetup(this.organisationBasicSetup, this.claims.Token)
      .subscribe(
        (data) => {
          if (data.statusCode == 0) {
            this.claims.UserId = data.responseInfo.userId;
            this.claims.EmailId = data.responseInfo.emailId;
            this.claims.IsMarqueeOn = data.responseInfo.isMarqueeOn;
            this.claims.MarqueeText = data.responseInfo.marqueeText;
            this.claimsService.updateClaims(this.claims);

            this.authenticationService
              .organisationdetails(
                data.responseInfo.userId.toString(),
                data.responseInfo.emailId,
                this.claims.Token
              )
              .subscribe((orgData) => {
                if (orgData.statusCode == 0) {
                  this.claims.OrgId = orgData.responseInfo[0].orgId;
                  this.claims.CompanyApproverRule =
                    orgData.responseInfo[0].companyApproverRule;
                  this.claims.isUniversalImportAllowed =
                    orgData.responseInfo[0].isUniversalImportAllowed;
                  this.claims.RateType = orgData.responseInfo[0].rateType;
                  this.claims.IsAPAutomationAllow =
                    orgData.responseInfo[0].isAPAutomationAllow;
                  this.claims.BandId = orgData.responseInfo[0].bandId;
                  this.claims.TwoStepApproval =
                    orgData.responseInfo[0].twoStepApproval;

                  this.claims.isClaimOnWebAllowed =
                    orgData.responseInfo[0].isClaimOnWebAllowed;
                  this.claims.orgRoleId = orgData.responseInfo[0].roleId;

                  this.claimsService.updateClaims(this.claims);
                  //this.router.navigate(["/onboard/onboardingsummary"]);
                  this.oauthService
                    .fetchTokenUsingPasswordFlowAndLoadUserProfile(
                      this.claims.LoginId,
                      "testpassword"
                    )
                    .then(() => {
                      // var tokenNew = this.oauthService.getAccessToken();
                      // this.claims.Token = tokenNew;
                      // this.authenticationService.setregisternav();
                      // this.claims.IsFinanceManager = true;
                      // this.claims.IsOnboardingCompleted = false;
                      // this.claims.FMRoleType = 1;
                      // this.claims.ApprovalRuleSetting = 1;
                      // this.claimsService.updateClaims(this.claims);
                      this.ngxService.stop();
                      this.gotoNextStepEvent.emit();
                    })
                    .catch((err) => {
                      console.error("error logging in", err);
                    });
                }
              });
          } else {
          }
        },
        (error) => {}
      );
  }

  bindVersionHistory() {
    if (!this.claims.appVersion) {
      this.versionHistoryService.getVersionHistory().subscribe((res: any) => {
        if (res.statusCode == 0) {
          let currentVersion = res.responseInfo.filter(
            (x) => x.isCurrentRelease
          )[0].versionNumber;

          this.claims.appVersion = currentVersion;
          this.claimsService.updateClaims(this.claims);
        }
      });
    }
  }

  onFirstNameChange(event: Event) {
    this.hasValidData = true;
  }

  ShowSpecialCharError: boolean = true;
  omit_special_char(event) {
    var k;
    k = event.charCode;
    this.ShowSpecialCharError = this.allowAcuteChar(event);
    return this.allowAcuteChar(event);
  }

  allowAcuteChar(e) {
    let regex = new RegExp("^[a-zA-ZÀ-úp{L} '-]+$");
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    } else return false;
  }

  noWhiteSpaceAtTheStart(event) {
    if (/^\s/.test(event.target.value.trim())) {
      event.target.value = "";
    }
  }

  onEmailFocusOut(event: Event) {
    var email = (<HTMLInputElement>event.target).value;

    if (this.IsDuplicate || !this.validateEmail(email)) {
      this.IsDuplicateGlobal = this.IsDuplicate;
      this.IsInValidEmailGlobal = !this.validateEmail(email);
      event.preventDefault();
      //(<HTMLInputElement>event.target).style.borderBottomColor = "red";
      // (<HTMLInputElement>event.target).focus();
    } else {
      // (<HTMLInputElement>event.target).style.borderBottomColor = "#707070";
      this.IsInValidEmailGlobal = !this.validateEmail(email);
      this.IsDuplicateGlobal = this.IsDuplicate;
    }
  }

  onEmailIdChange(event: Event) {
    this.hasValidData = true;
    this.validateAllInvitesWithObjects();
  }

  validateEmail(email) {
    if (email !== undefined) {
      return this.emailPattern.test(email);
    }
  }

  validateAllInvitesWithObjects() {
    this.validateAllInvites(this.newAttribute1);
    this.validateAllInvites(this.newAttribute2);
    this.validateAllInvites(this.newAttribute);

    for (var i = 0; i < this.fieldArray.length; i++) {
      this.validateAllInvites(this.fieldArray[i]);
    }
  }

  validateAllInvites(newAttributeObj) {
    if (newAttributeObj) {
      if (newAttributeObj.firstName) {
        newAttributeObj.firstName ==
          newAttributeObj.firstName.trim().replace(/ /g, "");
      }
      if (newAttributeObj.lastName) {
        newAttributeObj.lastName == newAttributeObj.lastName.replace(/ /g, "");
      }
      if (newAttributeObj.email == this.claims.EmailId) {
        newAttributeObj.isSelfInvite = true;
      } else {
        newAttributeObj.isSelfInvite = false;
      }

      if (newAttributeObj.email) {
        newAttributeObj.email == newAttributeObj.email.replace(/ /g, "");
        newAttributeObj.isInvalidEmail = !this.validateEmail(
          newAttributeObj.email
        );

        if (this.checkForDuplicateEmail(newAttributeObj.email)) {
          newAttributeObj.IsDuplicate = true;
        } else {
          newAttributeObj.IsDuplicate = false;
        }

        if (!newAttributeObj.isInvalidEmail) {
          this.authenticationService
            .checkForUserExistance(newAttributeObj.email)
            .pipe(first())
            .subscribe(
              (userData) => {
                if (
                  userData.responseInfo != null &&
                  newAttributeObj.email != this.claims.EmailId
                ) {
                  newAttributeObj.isUserBelongsToOtherOrganisation = true;
                } else {
                  newAttributeObj.isUserBelongsToOtherOrganisation = false;
                }
              },
              (error) => {}
            );
        }
      }
    }
  }

  checkForDuplicateEmail(email) {
    var iCount = 0;
    if (this.newAttribute1.email == email) {
      iCount = iCount + 1;
    }

    if (this.newAttribute2.email == email) {
      iCount = iCount + 1;
    }
    if (this.newAttribute.email == email) {
      iCount = iCount + 1;
    }

    for (var i = 0; i < this.fieldArray.length; i++) {
      if (this.fieldArray[i].email == email) {
        iCount = iCount + 1;
      }
    }
    return iCount > 1;
  }
}
