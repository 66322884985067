import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "../home";
import { AuthGuardService } from "../_services/common/authguard.service";
import { CalanderSchedulerComponent } from "../calander-scheduler/calander-scheduler.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,canActivate: [AuthGuardService]
  },
  {
    path: "settings",
    loadChildren: () =>
      import("../_modules/modules/org-setting.module").then(
        (m) => m.OrgSettingModule
      ),
  },
  {
    path: "onboard",
    loadChildren: () =>
      import("../user/registration.module").then((m) => m.RegistrationModule),
  },
  {
    path: "export",
    loadChildren: () =>
      import("../_modules/modules/accounting-integration.module").then(
        (m) => m.AccountingIntegrationModule
      ),
  },

  {
    path: "twostepapproval",
    loadChildren: () =>
      import("../_modules/modules/twostep.module").then((m) => m.TwoStepModule),
  },
  {
    path: "cccustomer",
    loadChildren: () =>
      import("../_modules/modules/costcentre.module").then(
        (m) => m.CostCentreModule
      ),
  },
  {
    path: "counterApp",
    loadChildren: () =>
      import("../_modules/modules/counter-approver-module.module").then(
        (m) => m.CounterApproverModuleModule
      ),
  },
  {
    path: "fxRateSetup",
    loadChildren: () =>
      import("../_modules/modules/fx-rate.module").then((m) => m.FxRateModule),
  },
  {
    path: "vendorSetup",
    loadChildren: () =>
      import("../_modules/modules/vendor.module").then(
        (m) => m.VendorModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/manage-employees.module").then(
        (m) => m.ManageEmployeesModule
      ),
  },
  {
    path: "setupyourorganisation",
    loadChildren: () =>
      import("../_modules/modules/setup-org.module").then(
        (m) => m.SetupOrgModule
      ),
  },
  {
    path: "currency",
    loadChildren: () =>
      import("../_modules/modules/currency.module").then(
        (m) => m.CurrencyModule
      ),
  },
  {
    path: "changepassword",
    loadChildren: () =>
      import("../_modules/modules/change-password.module").then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: "billingplan",
    loadChildren: () =>
      import("../_modules/modules/billing-plan.module").then(
        (m) => m.BillingPlanModule
      ),
  },
  {
    path: "referandearn",
    loadChildren: () =>
      import("../_modules/modules/refer-and-earn.module").then(
        (m) => m.ReferAndEarnModule
      ),
  },
  {
    path: "deletedata",
    loadChildren: () =>
      import("../_modules/modules/delete-data.module").then(
        (m) => m.DeleteDataModule
      ),
  },
  {
    path: "preapproval",
    loadChildren: () =>
      import("../_modules/modules/preapproval.module").then(
        (m) => m.PreapprovalModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/grade.module").then((m) => m.GradeModule),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/creditcard.module").then(
        (m) => m.CreditCardModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/manage-category.module").then(
        (m) => m.ManageCategoryModule
      ),
  },
  {
    path: "policydashboard",
    loadChildren: () =>
      import("../_modules/modules/policydashboard.module").then(
        (m) => m.PolicydashboardModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../_modules/modules/policy.module").then((m) => m.PolicyModule),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../advance-mileage/advance-mileage.module").then(
        (m) => m.AdvanceMileageModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../_modules/modules/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "card",
    loadChildren: () =>
      import("../_modules/modules/creditcard.module").then((m) => m.CreditCardModule),
  },
  {
    path: "pay",
    loadChildren: () =>
      import("../_modules/modules/pay.module").then((m) => m.PayModule),
  },
  {
    path: "financeapprover",
    loadChildren: () =>
      import("../_modules/modules/finance-approve.module").then(
        (m) => m.FinanceApproveModule
      ),
  },
  {
    path: "approver",
    loadChildren: () =>
      import("../_modules/modules/approver.module").then(
        (m) => m.ApproverModule
      ),
  },
  {
    path: "taxSetup",
    loadChildren: () =>
      import("../_modules/modules/tax.module").then((m) => m.TaxModule),
  },
  {
    path: "yesbank",
    loadChildren: () =>
      import("../_modules/modules/yes-bank.module").then(
        (m) => m.YesBankModule
      ),
  },
  {
    path: "ManageSubscription",
    loadChildren: () =>
      import("../_modules/modules/manage-subscription.module").then(
        (m) => m.ManageSubscriptionModule
      ),
  },
  {
    path: "mysubscription",
    loadChildren: () =>
      import("../_modules/modules/mysubscription.module").then(
        (m) => m.MysubscriptionModule
      ),
  },
  {
    path: "addtocart",
    loadChildren: () =>
      import("../_modules/modules/add-to-cart.module").then(
        (m) => m.AddToCartModule
      ),
  },
  {
    path: "shoppingcartlist",
    loadChildren: () =>
      import("../_modules/modules/shoppingcartlist.module").then(
        (m) => m.ShoppingcartlistModule
      ),
  },
  {
    path: "shoppingcartreview",
    loadChildren: () =>
      import("../_modules/modules/shoppingcart-review.module").then(
        (m) => m.ShoppingcartReviewModule
      ),
  },
  {
    path: "shoppingcartConfirmed",
    loadChildren: () =>
      import("../_modules/modules/shoppingcart-confirm.module").then(
        (m) => m.ShoppingcartConfirmModule
      ),
  },
  {
    path: "pricing",
    loadChildren: () =>
      import("../_modules/modules/pricing.module").then((m) => m.PricingModule),
  },
  {
    path: "comingsoon",
    loadChildren: () =>
      import("../_modules/modules/coming-soon.module").then(
        (m) => m.ComingSoonModule
      ),
  },
  {
    path: "export",
    loadChildren: () =>
      import("../export/export.module").then((m) => m.ExportModule),
  },
  {
    path: "support",
    loadChildren: () =>
      import("../_modules/modules/support.module").then((m) => m.SupportModule),
  },
  {
    path: "liabilityreport",
    loadChildren: () =>
      import("../liability-report/liability-report.module").then(
        (m) => m.LiabilityReportModule
      ),
  },
  {
    path: "yearendrule",
    loadChildren: () =>
      import("../_modules/modules/year-end-rule.module").then(
        (m) => m.YearEndRuleModule
      ),
  },
  {
    path: "carbonFPrint",
    loadChildren: () =>
      import("../_modules/modules/carbon-footprint.module").then(
        (m) => m.CarbonFootprintModule
      ),
  },

  {
    path: "cccustomer",
    loadChildren: () =>
      import("../_modules/modules/customer.module").then(
        (m) => m.CustomerModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/business-purpose-module.module").then(
        (m) => m.BusinessPurposeModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/duplicate.module").then(
        (m) => m.DuplicateModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/overall-daily-limit.module").then(
        (m) => m.OverallDailyLimitModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/expense-escalation.module").then(
        (m) => m.ExpenseEscalationModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/expense-time-limit.module").then(
        (m) => m.ExpenseTimeLimitModule
      ),
  },
  { path: "orgsetting", loadChildren: () => import("../_modules/modules/pernightlimit.module").then((m) => m.PernightlimitModule), },
  { path: "orgsetting", loadChildren: () => import("../_modules/modules/perpersonlimit.module").then((m) => m.PerpersonlimitModule), },
  { path: "orgsetting", loadChildren: () => import("../_modules/modules/daily-capping.module").then((m) => m.DailyCappingModule), },
  { path: "orgsetting", loadChildren: () => import("../_modules/modules/monthly-capping.module").then((m) => m.MonthlyCappingModule), },
  { path: "orgsetting", loadChildren: () => import("../_modules/modules/yearly-capping.module").then((m) => m.YearlyCappingModule), },
  { path: "orgsetting", loadChildren: () => import("../_modules/modules/autoapproval.module").then((m) => m.AutoapprovalModule), },
  { path: "dutyofcare", loadChildren: () => import("../_modules/modules/dutyofcaresetup.module").then((m) => m.DutyofcaresetupModule), },
  {
    path: "audittrail",
    loadChildren: () =>
      import("../audit-trail/audit-trail.module").then(
        (m) => m.AuditTrailModule
      ),
  },
  {
    path: "manualvat",
    loadChildren: () =>
      import("../_modules/modules/manual-vat.module").then(
        (m) => m.ManualVatModule
      ),
  },
  {
    path: "outofoffice",
    loadChildren: () =>
      import("../out-of-office/out-of-office.module").then(
        (m) => m.OutOfOfficeModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/additional-fields.module").then(
        (m) => m.AdditionalFieldsModule
      ),
  },
  {
    path: "secondaryfm",
    loadChildren: () =>
      import("../_modules/modules/secondary-fm.module").then(
        (m) => m.SecondaryFMModule
      ),
  },
  {
    path: "advances",
    loadChildren: () =>
      import("../advances/advances.module").then((m) => m.AdvancesModule),
  },
  {
    path: "openapi",
    loadChildren: () =>
      import("../_modules/modules/open-api.module").then(
        (m) => m.OpenApiModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/project-budget.module").then(
        (m) => m.ProjectBudgetModule
      ),
  },
  {
    path: "nrfc",
    loadChildren: () =>
      import("../_modules/modules/nrfc-mileage.module").then(
        (m) => m.NRFCMileageModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/multiple-companies.module").then(
        (m) => m.MultipleCompaniesModule
      ),
  },
  {
    path: "ahv",
    loadChildren: () =>
      import("../_modules/modules/approver-historical-view.module").then(
        (m) => m.ApproverHistoricalViewModule
      ),
  },
  {
    path: "csm",
    loadChildren: () =>
      import("../_modules/modules/compliance-statement.module").then(
        (m) => m.ComplianceStatementModule
      ),
  },
  {
    path: "amc",
    loadChildren: () =>
      import("../_modules/modules/anti-fraud.module").then(
        (m) => m.AntiFraudModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/location.module").then(
        (m) => m.LocationModule
      ),
  },
  {
    path: "claimant",
    loadChildren: () =>
      import("../_modules/modules/claimant-on-web.module").then(
        (m) => m.ClaimantOnWebModule
      ),
  },
  {
    path: "attendeetype",
    loadChildren: () =>
      import("../attendee-type/attendee-type.module").then(
        (m) => m.AttendeeTypeModule
      ),
  },
  {
    path: "leavemanagement",
    loadChildren: () =>
      import("../leave-management/leave-management.module").then(
        (m) => m.LeaveManagementModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/supplier.module").then(
        (m) => m.SupplierModule
      ),
  },
  {
    path: "universalimport",
    loadChildren: () =>
      import("../universal-import/universal-import.module").then(
        (m) => m.UniversalImportModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/multiple-countries.module").then(
        (m) => m.MultipleCountriesModule
      ),
  },
  {
    path: "orgsetting",
    loadChildren: () =>
      import("../_modules/modules/delegate.module").then(
        (m) => m.DelegateModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../_modules/modules/mileage-analysis.module").then(
        (m) => m.MileageAnalysisModule
      ),
  },

  {
    path: "dashboard",
    loadChildren: () =>
      import("../_modules/modules/policy.module").then((m) => m.PolicyModule),
  },

  

 

 
  
 
 
  {
    path: "essential-pack",
    loadChildren: () =>
      import("../_modules/modules/essential-pack.module").then((m) => m.EssentialPackModule),
  },
  {
    path: "disappearingExpenses",
    loadChildren: () =>
      import("../_modules/modules/disappearing-expenses.module").then(
        (m) => m.DisappearingExpenseModule
      ),
  },
    { path: "calander", component: CalanderSchedulerComponent },
    {
        path: "split-categories",
        loadChildren: () =>
            import("../_modules/modules/split-categories.module").then((m) => m.SplitCategoriesModule),
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
