import { Component, ViewEncapsulation } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'radio-cell',
  templateUrl: './radio-renderer.component.html'
})
export class RadioRendererComponent implements ICellRendererAngularComp {
  isSelected: boolean;
  isCheckboxDisabled: boolean;
  params: any;
  displayText: string = "";

  constructor() {
  }

  initilize(params: ICellRendererParams) {
    if (params.data) {
      this.params = params;
      this.displayText = params.getValue();
      this.isSelected = params.data.selected;
    }
  }

  onClick(event) {
    if (this.params.onClick instanceof Function) {
        let params = { event: event, rowData: this.params.node.data }
        this.params.onClick(params);
     }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}




