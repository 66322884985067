import { NgModule } from "@angular/core";
import { VideoIconComponent } from "./video-icon";
import { TranslateModule } from "@ngx-translate/core";
import { EditIconForGridComponent } from "./Icons/edit-icon-for-grid/edit-icon-for-grid.component";
import { SaveIconComponent } from "./Icons/save-icon/save-icon.component";
import { BackToApprovalComponent } from "./Icons/back-to-approval/back-to-approval.component";
import { BackToSettingsComponent } from "./Icons/back-to-settings/back-to-settings.component";
import { FourWheelerIconComponent } from "./Icons/four-wheeler-icon/four-wheeler-icon.component";
import { TwoWheelerIconComponent } from "./Icons/two-wheeler-icon/two-wheeler-icon.component";
import { NoVehicleIconComponent } from "./Icons/no-vehicle-icon/no-vehicle-icon.component";
import { EditIconComponent } from "./Icons/edit-icon/edit-icon.component";
import { CloseIconComponent } from "./Icons/close-icon/close-icon.component";
import { ConfirmIconComponent } from "./Icons/confirm-icon/confirm-icon.component";
import { ImportIconComponent } from "./Icons/import-icon/import-icon.component";
import { AssignApproverIconComponent } from "./Icons/assign-approver-icon/assign-approver-icon.component";
import { InviteEmployeesIconComponent } from "./Icons/invite-employees-icon/invite-employees-icon.component";
import { ResendInviteIconComponent } from "./Icons/resend-invite-icon/resend-invite-icon.component";
import { DeactivateEmployeesIconComponent } from "./Icons/deactivate-employees-icon/deactivate-employees-icon.component";
import { UpdateVehicleIconComponent } from "./Icons/update-vehicle-icon/update-vehicle-icon.component";
import { UpdateRoleIconComponent } from "./Icons/update-role-icon/update-role-icon.component";
import { ActivateEmployeesIconComponent } from "./Icons/activate-employees-icon/activate-employees-icon.component";
import {
  SafeUrlPipeline,
  GrdFilterPipe,
  OrderByPipe,
  EODDateFormatPipe,
  DateFormatPipe,
} from "./_helpers/pipe.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import {
  NgbProgressbarModule,
  NgbPopoverModule,
  NgbCarouselModule,
  NgbModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { BackToParentComponent } from "./Icons/back-to-parent/back-to-parent.component";
import { AddToCartComponent } from "./Icons/add-to-cart/add-to-cart.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { VideoTutorialIconComponent } from "./Icons/video-tutorial-icon/video-tutorial-icon.component";
import { NoCommaPipe } from "./_helpers/no-comman.pipe";
import { BackManageEmployeeComponent } from "./Icons/back-manage-employee/back-manage-employee.component";
import { BackToImportComponent } from "./Icons/back-to-import/back-to-import.component";
import { AssignIconToClaimantsComponent } from "./Icons/assign-icon-to-claimants/assign-icon-to-claimants.component";
import { AddIconComponent } from "./Icons/add-icon/add-icon.component";
import { LogIconComponent } from "./Icons/log-icon/log-icon.component";
import { DeactivateMinusIconComponent } from "./Icons/deactivate-minus-icon/deactivate-minus-icon.component";
import { ActivatePlusIconComponent } from "./Icons/activate-plus-icon/activate-plus-icon.component";
import { DeactivateMinusIconFalComponent } from "./Icons/deactivate-minus-icon-fal/deactivate-minus-icon-fal.component";
import { ActivatePlusIconFalComponent } from "./Icons/activate-plus-icon-fal/activate-plus-icon-fal.component";
import { UpdatePlaceholderDirective } from "./_directives/update-placeholder.directive";
import { NumericDirective } from "./_directives/commonnumber.directive";
import { ScrollSpyDirective } from "./_directives/scroll-spy.directive";
import {
  MaxDirective,
  MinDirective,
} from "./_directives/commonnumber.directive";
import { MarkAsteriskDirective } from "./_directives/mark-asterisk.directive";
import { EllipsisModule } from "ngx-ellipsis";
import { BlockCutCopyPasteDirective } from "./_directives/block-cut-copy-paste.directive";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { SetAccessRightsComponent } from "./Icons/set-access-rights/set-access-rights.component";
import { LoaderComponent } from "./shared/loader/loader.component";
import { AlphaNumericDirective } from "./_directives/alphanumeric.directive";
import { AlertModule } from "./alert-panel/alert.module";
import { MarketingPopupModule } from "./shared/panels/marketing-popup/marketing-popup.module";
import { XeroIconComponent } from "./Icons/xero-icon/xero-icon.component";
import { SecondaryFMIconComponent } from "./Icons/seconday-fm-icon/seconday-fm-icon.component";
import { TwoDigitDecimaNumberDirective } from "./_directives/two-digit-decima-number.directive";
import { ViewReceiptComponent } from "./shared/panels/view-receipt/view-receipt.component";
import { ViewDocumentComponent } from "./shared/panels/view-document/view-document.component";
import { PdfViewerComponent } from "./shared/panels/pdf-viewer/pdf-viewer.component";
import { PdfViewerSecondPanelComponent } from "./shared/panels/pdf-viewer-second-panel/pdf-viewer-second-panel.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { CalendarComponent } from "./Icons/calendar/calendar.component";
import { GoToProjectBudgetIconComponent } from "./Icons/goto-project-budget-icon/goto-project-budget-icon.component";
import { InformationAlertPanelModule } from "./shared/panels/information-alert-panel/information-alert-panel.module";
import { CalendarInGridIconComponent } from "./Icons/calendar-ingrid-icon/calendar-ingrid-icon.component";

import { NRFuelComponent } from "./Icons/nr-fuel/nr-fuel.component";
import { FuelCardComponent } from "./Icons/fuel-card/fuel-card.component";
import { CategoryIconsComponent } from "./Icons/WorkflowPanel/category-icons/category-icons.component";
import { dynamicDigitBeforeAndDynamicDigitAfterDecimalNumberDirective } from "./_directives/six-digit-before-and-two-digit-after-decimal-number";
import { TwoDigitBeforeAndTwoDigitAfterDecimalNumberDirective } from "./_directives/two-digit-before-and-two-digit-after-decimal-number";
import { SixDigitBeforeAndAfterDecimalNumberDirective } from "./_directives/six-digit-before-and-after-decimal-number";
import { ThreeDigitBeforeAndTwoDigitAfterDecimalNumberDirective } from "./_directives/three-digit-before-and-two-digit-after-decimal-number";
import { FileUploaderComponent } from "./shared/file-uploader/file-uploader.component";
import { FileDirective } from "./_directives/file.directive";
import { MileageDetailComponent } from "./shared/panels/mileage-detail/mileage-detail.component";
import { ViewTagPanelComponent } from "./shared/forms/selection-panels/view-tag-panel/view-tag-panel.component";
import { InformationImagePanelComponent } from "./shared/panels/information-image-panel/information-image-panel.component";
import { FileDropDirective } from "./_directives/file-drop.directive";
import { SetupIconComponent } from "./Icons/setup-icon/setup-icon.component";
import { SelectForeignCurrencyPanelComponent } from "./shared/forms/selection-panels/select-foreign-currency-panel/select-foreign-currency-panel.component";
import { AttachReceiptOptionsPanelComponent } from "./shared/forms/selection-panels/attach-receipt-options-panel/attach-receipt-options-panel.component";
import { ExpenseMergePanelComponent } from "./shared/forms/selection-panels/expense-merge-panel/expense-merge-panel.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { ViewAttendeeTypePanelComponent } from "./shared/panels/view-attendee-type-panel/view-attendee-type-panel.component";
import { AddPassengerLoadPanelComponent } from "./shared/forms/selection-panels/add-passenger-load-panel/add-passenger-load-panel.component";
import { WizardComponent } from "src/app/shared/panels/wizard/wizard.component";
import { InfoModelPopupComponent } from "./shared/panels/info-model-popup/info-model-popup.component";
import { StripeInvoiceFailedDetailsPanelComponent } from "./shared/panels/stripe-invoice-failed-details-panel/stripe-invoice-failed-details-panel.component";
import { EightDigitBeforeAndTwoDigitAfterDecimalNumberDirective } from "./_directives/eight-digit-before-and-two-digit-after-decimal-number";
import { ViewFormDocumentComponent } from "./shared/panels/view-form-documents/view-form-documents.component";
import { ImagePreviewDetailComponent } from "./shared/panels/img-preview/img-preview.component";
import { MovableDirective } from "./_directives/movable.directive";
import { DraggableDirective } from "./_directives/draggable.directive";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ReceiptViewerComponent } from "src/app/shared/panels/receipt-viewer/receipt-viewer.component";
import { BackToWizardComponent } from "./Icons/back-to-wizard/back-to-wizard.component";
import { MultiButtonAlertPanelComponent } from "./shared/panels/multi-button-alert-panel/multi-button-alert-panel.component";
import { PasswordStrengthMeterModule } from "angular-password-strength-meter";
import { PagetitleComponent } from "./shared/pagetitle/pagetitle.component";
import { WidgetModule } from "./shared/widget/widget.module";
import { ViewFormReceiptComponent } from "./shared/panels/view-form-receipt/view-form-receipt.component";
import { StripeAgreementPanelComponent } from "src/app/shared/panels/stripe-agreement-panel/stripe-agreement-panel.component";
import { BasicFunctionSubscribedPanelComponent } from "src/app/shared/panels/basic-function-subscribed-panel/basic-function-subscribed-panel.component";
import { BasicFunctionTrialEndsAlertPanelComponent } from "src/app/shared/panels/basicFunctionTrialEndsAlertPanel/basicFunctionTrialEndsAlertPanel.component";
import { BasicFunctionExploreAlertPanelComponent } from "src/app/shared/panels/basic-function-explore-alert-panel/basic-function-explore-alert-panel.component";
import { FlatpickrModule } from "angularx-flatpickr";
import { ViewReceiptDetialsComponent } from "./shared/panels/view-receipt-new/view-receipt-new.component";
import { DuplicateReceiptPanelComponent } from "./two-step-approval/common/finance-approval/duplicate-receipt-panel/duplicate-receipt-panel.component";

@NgModule({
  imports: [
    TranslateModule,
    InfiniteScrollModule,
    AlertModule,
    MarketingPopupModule,
    NgbModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbCarouselModule,
    NgSelectModule,
    PasswordStrengthMeterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EllipsisModule,
    NgxUiLoaderModule,
    NgxExtendedPdfViewerModule,
    InformationAlertPanelModule,
    GoogleMapsModule,
    DragDropModule,
    CommonModule,
    WidgetModule,
    FlatpickrModule.forRoot()
  ],
  declarations: [
    AttachReceiptOptionsPanelComponent,
    VideoIconComponent,
    SaveIconComponent,
    BackToSettingsComponent,
    BackToApprovalComponent,
    FourWheelerIconComponent,
    TwoWheelerIconComponent,
    NoVehicleIconComponent,
    CloseIconComponent,
    ConfirmIconComponent,
    ImportIconComponent,
    AssignApproverIconComponent,
    InviteEmployeesIconComponent,
    ResendInviteIconComponent,
    DeactivateEmployeesIconComponent,
    UpdateVehicleIconComponent,
    UpdateRoleIconComponent,
    ActivateEmployeesIconComponent,
    SafeUrlPipeline,
    BackToParentComponent,
    AddToCartComponent,
    SecondaryFMIconComponent,
    GrdFilterPipe,
    NoCommaPipe,
    OrderByPipe,
    VideoTutorialIconComponent,
    AssignIconToClaimantsComponent,
    AddIconComponent,
    LogIconComponent,
    DeactivateMinusIconComponent,
    ActivatePlusIconComponent,
    DeactivateMinusIconFalComponent,
    ActivatePlusIconFalComponent,
    EditIconForGridComponent,
    EditIconComponent,
    BackManageEmployeeComponent,
    BackToImportComponent,
    UpdatePlaceholderDirective,
    NumericDirective,
    MinDirective,
    MaxDirective,
    MarkAsteriskDirective,
    ScrollSpyDirective,
    MarkAsteriskDirective,
    BlockCutCopyPasteDirective,
    SetAccessRightsComponent,
    LoaderComponent,
    AlphaNumericDirective,
    XeroIconComponent,
    TwoDigitDecimaNumberDirective,
    GoToProjectBudgetIconComponent,
    ViewReceiptComponent,
    ViewReceiptDetialsComponent,
    ViewDocumentComponent,
    ViewFormDocumentComponent,
    PdfViewerComponent,
    PdfViewerSecondPanelComponent,
    CalendarComponent,
    CalendarInGridIconComponent,
    NRFuelComponent,
    FuelCardComponent,
    CategoryIconsComponent,
    dynamicDigitBeforeAndDynamicDigitAfterDecimalNumberDirective,
    TwoDigitBeforeAndTwoDigitAfterDecimalNumberDirective,
    SixDigitBeforeAndAfterDecimalNumberDirective,
    ThreeDigitBeforeAndTwoDigitAfterDecimalNumberDirective,
    FileUploaderComponent,
    FileDirective,
    MileageDetailComponent,
    ViewTagPanelComponent,
    FileDropDirective,
    InformationImagePanelComponent,
    SetupIconComponent,
    SelectForeignCurrencyPanelComponent,
    AttachReceiptOptionsPanelComponent,
    ExpenseMergePanelComponent,
    ViewAttendeeTypePanelComponent,
    AddPassengerLoadPanelComponent,
    WizardComponent,
    InfoModelPopupComponent,
    StripeInvoiceFailedDetailsPanelComponent,
    ReceiptViewerComponent,
    BackToWizardComponent,
    EightDigitBeforeAndTwoDigitAfterDecimalNumberDirective,
    MultiButtonAlertPanelComponent,
    ImagePreviewDetailComponent,
    MovableDirective,
    DraggableDirective,
    PagetitleComponent,
    EODDateFormatPipe,
    DateFormatPipe,
    ViewFormReceiptComponent,
    StripeAgreementPanelComponent,
    BasicFunctionSubscribedPanelComponent,
    BasicFunctionTrialEndsAlertPanelComponent,
    BasicFunctionExploreAlertPanelComponent,
    DuplicateReceiptPanelComponent
  ],
  providers: [EODDateFormatPipe, DateFormatPipe],

  exports: [
    NgbTooltipModule,
    VideoIconComponent,
    SaveIconComponent,
    BackToSettingsComponent,
    BackToApprovalComponent,
    FourWheelerIconComponent,
    TwoWheelerIconComponent,
    NoVehicleIconComponent,
    EditIconComponent,
    CloseIconComponent,
    ConfirmIconComponent,
    ImportIconComponent,
    AssignApproverIconComponent,
    InviteEmployeesIconComponent,
    ResendInviteIconComponent,
    DeactivateEmployeesIconComponent,
    UpdateVehicleIconComponent,
    UpdateRoleIconComponent,
    ActivateEmployeesIconComponent,
    ViewReceiptComponent,
    ViewReceiptDetialsComponent,
    ViewDocumentComponent,
    ViewFormDocumentComponent,
    PdfViewerComponent,
    PdfViewerSecondPanelComponent,
    SafeUrlPipeline,
    GrdFilterPipe,
    NoCommaPipe,
    InfiniteScrollModule,
    AlertModule,
    MarketingPopupModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbCarouselModule,
    NgSelectModule,
    BackToParentComponent,
    AddToCartComponent,
    SecondaryFMIconComponent,
    OrderByPipe,
    VideoTutorialIconComponent,
    AssignIconToClaimantsComponent,
    AddIconComponent,
    LogIconComponent,
    DeactivateMinusIconComponent,
    ActivatePlusIconComponent,
    DeactivateMinusIconFalComponent,
    BackManageEmployeeComponent,
    BackToImportComponent,
    ActivatePlusIconFalComponent,
    EditIconForGridComponent,
    PasswordStrengthMeterModule,
    UpdatePlaceholderDirective,
    NumericDirective,
    MinDirective,
    MaxDirective,
    MarkAsteriskDirective,
    ScrollSpyDirective,
    EllipsisModule,
    BlockCutCopyPasteDirective,
    NgxUiLoaderModule,
    LoaderComponent,
    SetAccessRightsComponent,
    AlphaNumericDirective,
    XeroIconComponent,
    TwoDigitDecimaNumberDirective,
    GoToProjectBudgetIconComponent,
    CalendarComponent,
    InformationAlertPanelModule,
    CalendarInGridIconComponent,
    NRFuelComponent,
    FuelCardComponent,
    CategoryIconsComponent,
    dynamicDigitBeforeAndDynamicDigitAfterDecimalNumberDirective,
    TwoDigitBeforeAndTwoDigitAfterDecimalNumberDirective,
    SixDigitBeforeAndAfterDecimalNumberDirective,
    ThreeDigitBeforeAndTwoDigitAfterDecimalNumberDirective,
    FileUploaderComponent,
    FileDirective,
    MileageDetailComponent,
    ViewTagPanelComponent,
    FileDropDirective,
    InformationImagePanelComponent,
    SetupIconComponent,
    SelectForeignCurrencyPanelComponent,
    AttachReceiptOptionsPanelComponent,
    ExpenseMergePanelComponent,
    ViewAttendeeTypePanelComponent,
    AddPassengerLoadPanelComponent,
    WizardComponent,
    InfoModelPopupComponent,
    StripeInvoiceFailedDetailsPanelComponent,
    ReceiptViewerComponent,
    BackToWizardComponent,
    EightDigitBeforeAndTwoDigitAfterDecimalNumberDirective,
    AttachReceiptOptionsPanelComponent,
    MultiButtonAlertPanelComponent,
    ImagePreviewDetailComponent,
    MovableDirective,
    DraggableDirective,
    ViewReceiptComponent,
    ViewDocumentComponent,
    PdfViewerComponent,
    PdfViewerSecondPanelComponent,
    MileageDetailComponent,
    ViewTagPanelComponent,
    InformationImagePanelComponent,
    ViewFormDocumentComponent,
    AttachReceiptOptionsPanelComponent,
    ReceiptViewerComponent,
    ImagePreviewDetailComponent,
    PagetitleComponent,
    WidgetModule,
    EODDateFormatPipe,
    DateFormatPipe,
    ViewFormReceiptComponent,
    StripeAgreementPanelComponent,
    BasicFunctionSubscribedPanelComponent,
    BasicFunctionTrialEndsAlertPanelComponent,
    BasicFunctionExploreAlertPanelComponent,
    DuplicateReceiptPanelComponent
  ],
})
export class SharedModule {}
