<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu pl-1">
  <div data-simplebar class="h-100">
    <ngx-simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu" class="heightCalc-8 overflow-auto overflow-x-hidden">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled pr-1" id="side-menu">
          <ng-container *ngFor="let item of menuItems">
            <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>

            <li *ngIf="!item.isTitle && !item.isLayout" [ngbTooltip]="item.label" container="body" tooltipClass="left-menu-tooltip-class" [closeDelay]=".1">
              <a *ngIf="hasItems(item)" data-href="javascript:void(0);" class="is-parent"
                [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge, 'activeMenu': selectedMenuId == item.id}">
                <i class="bx {{selectedMenuId == item.id ? item.iconInactive :item.icon}}" *ngIf="item.icon"></i>
                <span> {{ item.label | translate }}</span>

                <span class="badge rounded-pill float-end bg-{{item.badge.variant}}"
                  *ngIf="item.badge">{{item.badge.text | translate}}</span>
              </a>

              <a data-href="javascript:void(0);" *ngIf="!hasItems(item)"
                class="side-nav-link-ref d-flex align-items-center"
                [ngClass]="{'mm-active': selectedMenuId == item.id, 'activeMenu': selectedMenuId == item.id }"
                (click)="navigateToSettings(item)">
                <img *ngIf="item.icon && selectedMenuId != item.id"
                  [src]="'assets/svg-icons/dashboard/' + item.iconInactive + '.svg'" alt="" width="18px">
                <img *ngIf="item.icon && selectedMenuId == item.id"
                  [src]="'assets/svg-icons/dashboard/' + item.icon + '.svg'" alt="" width="18px">
                <!-- <i class="" style="background-repeat: no-repeat"
                     alt="">


                <i class="" style="background-repeat: no-repeat"
                [ngStyle]="{background: 'url(/assets/svg-icons/dashboard/' + item.icon + '.svg)','background-repeat':'no-repeat', width: '20px', height: '20px'}"	
                   *ngIf="item.icon && selectedMenuId != item.id"></i>
                   <i class="" style="background-repeat: no-repeat"
                   [ngStyle]="{background: 'url(/assets/svg-icons/dashboard/' + item.iconInactive + '.svg)','background-repeat':'no-repeat', width: '20px', height: '20px'}"	
                      *ngIf="item.icon && selectedMenuId == item.id"></i> -->
                <span class="ml-2"> {{ item.label | translate }} <img *ngIf="item.isEssentialPackage"
                    style="position: absolute;right: 0;bottom: 15px;max-height: 15px;"
                    src="../../assets/svg-icons/functioncategory/active/crown.svg" class="h-2em mr-1" min alt=""></span>

                <span class="badge rounded-pill bg-{{item.badge.variant}} float-end ml-2"
                  *ngIf="item.badge">{{item.badge.text | translate}}</span>
              </a>
              <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems">
                  <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                    [attr.data-parent]="subitem.parentId">
                    <span>{{ subitem.label | translate}}</span>
                    <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end"
                      *ngIf="subitem.badge">{{subitem.badge.text | translate}}</span>
                  </a>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" data-href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    <span>{{ subitem.label | translate}}</span>
                    <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end"
                      *ngIf="subitem.badge">{{subitem.badge.text | translate}}</span>
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link"
                        class="side-nav-link-ref">
                        <span>{{ subSubitem.label | translate }}</span>

                      </a>
                    </li>
                  </ul>
                </li>
                
              </ul>
            </li>
            
          </ng-container>
        </ul>

      </div>
      <!-- Sidebar -->
      <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu list-unstyled w-100pc">
            <li class="d-flex justify-content-end mt-2" ngbTooltip="Get Started Video" container="body" tooltipClass="left-menu-tooltip-class" [closeDelay]=".1">
                <a href="javascript:void(0);" class="mm-active mm-collapsed  sidebar-watch-video" aria-expanded="false" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <span class="eod-text-dark fw-600">Get Started Video</span>
                    <img src="../../../assets/svg-icons/video-play.svg" alt="" width="30px">
                </a>
            </li>
        </ul>
      </div>
    </ngx-simplebar>
    
  </div>
</div>
<!-- Left Sidebar End -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" class="video-close">×</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe _ngcontent-ng-c4277079693="" width="100%" height="360" frameborder="0" webkitallowfullscreen=""
          mozallowfullscreen="" allowfullscreen="" src="https://www.youtube.com/embed/VOo0nkvYjn0?si=lzB-BJy3YPth5vhY"
          class="video-height-youtube">
        </iframe>
      </div>
    </div>
  </div>
</div>