import { OnInit, Component, EventEmitter, Input, Output, ViewChild, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { MileageDetailComponent } from "src/app/shared/panels/mileage-detail/mileage-detail.component";
import { ViewReceiptComponent } from "src/app/shared/panels/view-receipt/view-receipt.component";
import { ApiRequest, IApiRequest, MileageUOMEnum } from "src/app/_models";
import { ExpenseCurrency, PolicyViolationInputModel } from "src/app/_models/claimant-on-web/claimant-on-web";
import { IReceiptInfo, IRequestPolicyViolation, IResponsePolicyViolation, RequestPolicyViolation } from "src/app/_models/twostepapproval/claimant-expense-detail";
import { CurrencyService } from "src/app/_services/claimant/currency.service";
import { PolicyDataService } from "src/app/_services/policy/policy-data.service";
import { Claims } from "../../../../_models/common/claims";
import { ClaimsService } from "../../../../_services/common/claims.service";

@Component({
    selector: "policy-violation-panel",
    templateUrl: "./policy-violation-panel.component.html"
})
export class PolicyViolationPanelComponent implements OnInit {
    @Input()
    inputData: PolicyViolationInputModel;
    @Input() isReceiptNumberSettingsEnabled:boolean;
    @Input()
    zindex_Class: string;
    @Output() closePanelEvent = new EventEmitter<string>();
    param: any;
    getPolicyRequest: IApiRequest<IRequestPolicyViolation> = new ApiRequest<RequestPolicyViolation>("", 1, "");
    getPolicyResponse: IResponsePolicyViolation;
    isPolicyViolated: boolean = false;
    dailyCapVisible: boolean = false;
    monthlyCapVisible: boolean = false;
    yearlyCapVisible: boolean = false;
    duplicateVisible: boolean = false;
    vatNoVatVisible: boolean = false;
    overallVisible: boolean = false;
    perNightVisible: boolean = false;
    perItemVisible: boolean = false;
    expenseTimeLimitVisible: boolean = false;
    defaultVehicleTypeVisible:boolean=false;
    operationalInformationVisible:boolean=false;
    operationalInformationViolation: any;
    showPolicyViolation: boolean = false;
    currencytList: ExpenseCurrency[];
    @ViewChild('viewReceiptComponent') viewReceiptComponent: ViewReceiptComponent;
    receiptInfo: any;
    receiptCount: number = 0;
    hasReturnJourney: boolean = false;
    milesTravelled: any;
    mileageLabel: string;
    detourMiles: any;
    triangulationMiles: any;
    mileageRate: string;
    distance: any;
    origin: any;
    destination: any;
    receiptLat: number;
    receiptLong: number;
    latLongArray: any;
    currency: string;
    openSectionName: string;
    isNoteError: boolean = false;
    hasFormChanges: boolean = false;
    zindexClass:string="";
    claims: Claims;
    @ViewChild('mileageDetailComponent') mileageDetailComponent: MileageDetailComponent;
    constructor(
        private translate: TranslateService,
        private toastr: ToastrService,
        private policyDataService: PolicyDataService,
        private currencyService: CurrencyService,
        private claimsService: ClaimsService,
        private cd:ChangeDetectorRef
    ) {
        this.claimsService.currentClaims.subscribe((claims) => {
            this.claims = claims;
        });
    }

    ngOnInit() {
        if(this.zindex_Class)
              this.zindexClass = this.zindex_Class;
        this.getCurrrencyList();
        this.getPolicViolationList(this.inputData.expenseId);
    }

    fnClosePanel() {
        this.closePanelEvent.emit(this.inputData.justificationNotes);
    }

    getPolicViolationList(expenseId: number) {
        this.getPolicyRequest.requestInfo = new RequestPolicyViolation();
        this.getPolicyRequest.requestInfo.expenseId = expenseId;
        //this.getPolicyRequest.requestInfo.orgSubCategoryMappings = this.inputData.orgSubCategoryMappings;;

        this.policyDataService.GetPolicyData(this.getPolicyRequest)
            .pipe(first())
            .subscribe(
                data => {
                    if (data.statusCode == 0) {
                        this.getPolicyResponse = data.responseInfo;
                        if (this.getPolicyResponse.policyViolation.dailyCappingLimit != null)
                            this.dailyCapVisible = true;
                        if (this.getPolicyResponse.policyViolation.duplicate!=null && this.getPolicyResponse.policyViolation.duplicate.length > 0) {
                            if ((this.inputData.baseCategoryId == 4 ||
                                this.inputData.baseCategoryId == 5 ||
                                this.inputData.baseCategoryId == 6) &&
                                this.getPolicyResponse.policyViolation.duplicate[0].receiptInfo.length > 0) {
                                this.receiptInfo = this.getPolicyResponse.policyViolation.duplicate[0].receiptInfo;
                                if (this.receiptInfo) {
                                    this.receiptCount = this.receiptInfo.length;
                                }
                            }
                            this.duplicateVisible = true;
                        }
                        let operationalInfoViolation = this.getPolicyResponse.policyViolation.expenseOperationalInfo;
                        this.operationalInformationViolation = operationalInfoViolation != null && operationalInfoViolation.length != 0 ? this.getPolicyResponse.policyViolation.expenseOperationalInfo.filter(it => it.fieldValue == false) : [];
                  
                        if (this.operationalInformationViolation.length != 0) {
                            this.operationalInformationVisible=true;                            
                        }
                        if (this.getPolicyResponse.policyViolation.expenseTimeLimit != null)
                            this.expenseTimeLimitVisible = true;
                        if (this.getPolicyResponse.policyViolation.monthlyCappingLimit != null)
                            this.monthlyCapVisible = true;
                        if (this.getPolicyResponse.policyViolation.overallDailyLimit != null)
                            this.overallVisible = true;
                        if (this.getPolicyResponse.policyViolation.perNightLimit != null)
                            this.perNightVisible = true;
                        if (this.getPolicyResponse.policyViolation.perPersonLimit != null)
                            this.perItemVisible = true;
                        if (this.getPolicyResponse.policyViolation.vatNovatReceiptType != null)
                            this.vatNoVatVisible = true;
                        if (this.getPolicyResponse.policyViolation.yearlyCappingLimit != null)
                            this.yearlyCapVisible = true;
                        if(this.getPolicyResponse.policyViolation.defaultVehicleType!=null){
                            this.defaultVehicleTypeVisible=true;
                            }
                        this.fnGetOpenSectionName();
                    }
                    this.cd.detectChanges();

                },
                error => {
                    this.toastr.error(this.translate.instant("failed"));
                }
            );
    }

    getCurrrencyList() {
        this.currencyService.getAllExpenseCurrency(this.claims.OrgId)
            .subscribe((data: any) => {
                if (data && data.length > 0) {
                    this.currencytList = data;
                }
            });
    }

    getCurrencyName(currencyId: number) {
        if (this.currencytList) {
            return this.currencytList.filter(a => a.currencyId == currencyId).map(function (b) { return b.currencyCode })[0];
        }
    }

    fnShowReceiptsPanel(items: IReceiptInfo[]) {
        this.receiptInfo = items;
        if (this.receiptInfo) {
            this.receiptCount = this.receiptInfo.length;
        }
        this.viewReceiptComponent.fnShowReceiptsPanel(items);
    }

    fnShowmileageMapDetail() {
        this.mileageDetailComponent.fnMapData(true, 0, this.inputData.expenseDetailResponse);
    }

    fnSubmitPanel() {
        if (this.inputData.justificationNotes.trim()) {
            this.closePanelEvent.emit(this.inputData.justificationNotes.trim());
        } else {
            this.isNoteError = true;
        }
    }
    fnGetOpenSectionName() {
        if (this.expenseTimeLimitVisible) {
            this.openSectionName = "expenseTimeLimitVisible";
            return;
        } else if (this.vatNoVatVisible) {
            this.openSectionName = "vatNoVatVisible";
            return;
        } else if (this.duplicateVisible) {
            this.openSectionName = "duplicateVisible";
            return;
        } else if (this.overallVisible) {
            this.openSectionName = "overallVisible";
            return;
        } else if (this.dailyCapVisible) {
            this.openSectionName = "dailyCapVisible";
            return;
        } else if (this.monthlyCapVisible) {
            this.openSectionName = "monthlyCapVisible";
            return;
        }
        else if (this.yearlyCapVisible) {
            this.openSectionName = "yearlyCapVisible";
            return;
        } else if (this.perItemVisible) {
            this.openSectionName = "perItemVisible";
            return;
        } else if (this.perNightVisible) {
            this.openSectionName = "perNightVisible";
            return;
        }
        else if (this.defaultVehicleTypeVisible) {
            this.openSectionName = "defaultVehicleTypeVisible";
            return;
        }

        if (this.operationalInformationViolation.length != 0) {
            this.openSectionName = 'operationalInformationVisible';
            for (var i = 0; i < this.operationalInformationViolation.length; i++) {
              this.operationalInformationViolation[i].fieldValueString = "NO";
            }
          }
    }

}
