import { NgModule } from "@angular/core";
import { NgbTooltipModule, NgbModule, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { GridModule } from "./grid.module";
import { DynamicFormComponent } from "../../shared/forms/dynamic-form/dynamic-form.component";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyTagInputComponent } from "../../shared/forms/form-controls/formly-tag-input/formly-tag-input.component";
import { FormlyAdvanceBalanceInputComponent } from "../../shared/forms/form-controls/formly-advance-balance-input/formly-advance-balance-input.component";
import { FormlyTextInputComponent } from "../../shared/forms/form-controls/formly-text-input/formly-text-input.component";
import { FormlyIntegerInputComponent } from "../../shared/forms/form-controls/formly-integer-input/formly-integer-input.component";
import { FormlyTextareaInputComponent } from "../../shared/forms/form-controls/formly-textarea-input/formly-textarea-input.component";
import { FormlyNumberInputComponent } from "../../shared/forms/form-controls/formly-number-input/formly-number-input.component";
import { FormlyDisabledInputComponent } from "../../shared/forms/form-controls/formly-disabled-input/formly-disabled-input.component";
import { FormlyCalendarInputComponent } from "../../shared/forms/form-controls/formly-calendar-input/formly-calendar-input.component";
import { FormlyFieldInputPanelComponent } from "../../shared/forms/form-controls/formly-input-panel/formly-input-panel.component";
import { SelectCategoryPanelComponent } from "../../shared/forms/selection-panels/select-category-panel/select-category-panel.component";
import { SelectCustomerPanelComponent } from "../../shared/forms/selection-panels/select-customer-panel/select-customer-panel.component";
import { SelectCostCentrePanelComponent } from "../../shared/forms/selection-panels/select-cost-centre-panel/select-cost-centre-panel.component";
import { SelectProjectPanelComponent } from "../../shared/forms/selection-panels/select-project-panel/select-project-panel.component";
import { ViewExpensePolicyPanelComponent } from "../../shared/forms/selection-panels/view-expense-policy-panel/view-expense-policy-panel.component";
import { SelectPreApprovalTagPanelComponent } from "../../shared/forms/selection-panels/select-pre-approval-tag-panel/select-pre-approval-tag-panel.component";
import { CreateDetourPanelComponent } from "../../shared/forms/selection-panels/create-detour-panel/create-detour-panel.component";
import { SelectBusinessPurposePanelComponent } from "../../shared/forms/selection-panels/select-business-purpose-panel/select-business-purpose-panel.component";
import { SelectReceiptTypePanelComponent } from "../../shared/forms/selection-panels/select-receipt-type-panel/select-receipt-type-panel.component";
import { PolicyViolationPanelComponent } from "../../shared/forms/selection-panels/policy-violation-panel/policy-violation-panel.component";
import { AdvancePolicyViolationPanelComponent } from "../../shared/forms/selection-panels/advance-policy-violation-panel/advance-policy-violation-panel.component";
import { SelectStatePanelComponent } from "../../shared/forms/selection-panels/select-state-panel/select-state-panel.component";
import { SelectAdditionalFieldDataPanelComponent } from "../../shared/forms/selection-panels/select-additional-field-data-panel/select-additional-field-data-panel.component";
import { AssociateCreditCardExpensePanelComponent } from "../../shared/forms/selection-panels/associate-credit-card-expense-panel/associate-credit-card-expense-panel.component";
import { CreateTagPanelComponent } from "../../shared/forms/selection-panels/create-tag-panel/create-tag-panel.component";
import { SelectRecentJourneysPanelComponent } from "../../shared/forms/selection-panels/select-recent-journeys-panel/select-recent-journeys-panel.component";
import { AdvancePassbookPanelComponent } from "../../shared/forms/selection-panels/advance-passbook-panel/advance-passbook-panel.component";
import { FormlyNgSelectInputComponent } from "../../shared/forms/form-controls/formly-ng-select-input/formly-ng-select-input.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormlyNgSelectDescriptionInputComponent } from "src/app/shared/forms/form-controls/formly-ng-select-description-input/formly-ng-select-description-input.component";
import { FormlyUlComponent } from "../../shared/forms/form-controls/formly-ul/formly-ul.component";
import { FormlyRadioButtonComponent } from "../../shared/forms/form-controls/formly-radio-button/formly-radio-button.component";
import { FormlyVehicleButtonComponent } from "../../shared/forms/form-controls/formly-vehicle-button/formly-vehicle-button.component";
import { FormlyCheckboxInputComponent } from "../../shared/forms/form-controls/formly-checkbox-input/formly-checkbox-input.component";
import {
  EmailValidator,
  NoWhitespaceValidator,
} from "src/app/shared/forms/validators/validators";
import { FormlyReceiptComponent } from "../../shared/forms/form-controls/formly-receipt/formly-receipt.component";
import { FormlyNotesComponent } from "../../shared/forms/form-controls/formly-justification/formly-notes.component";
import { FormlyAdvancePolicyComponent } from "../../shared/forms/form-controls/formly-advance-policy/formly-advance-policy.component";
import { FormlyPolicyViolationComponent } from "../../shared/forms/form-controls/formly-policy-violation/formly-policy-violation.component";
import { FormlyAdvancePolicyViolationComponent } from "../../shared/forms/form-controls/formly-advance-policy-violation/formly-advance-policy-violation.component";
import { FormlyToggleInputComponent } from "../../shared/forms/form-controls/formly-toggle-input/formly-toggle-input.component";
import { SharedModule } from "src/app/shared.module";
import { FormlyRadioInputComponent } from "../../shared/forms/form-controls/formly-radio-input/formly-radio-input.component";
import { ActionPanelComponent } from "../../shared/panels/action-panel/action-panel.component";
import { FormlyNgMultiSelectInputComponent } from "../../shared/forms/form-controls/formly-ng-multi-select-input/formly-ng-multi-select-input.component";
import { FormlyCurrencyImageUploderComponent } from "../../shared/forms/form-controls/formly-currency-image-uploder/formly-currency-image-uploder.component";
import { FormlyButtonComponent } from "../../shared/forms/form-controls/formly-button/formly-button.component";
import { FormlyCalendarDateMonthInputComponent } from "../../shared/forms/form-controls/formly-calendar-date-month-input/formly-calendar-date-month-input.component";
import { FormlyToggleInputLeftAlignedComponent } from "../../shared/forms/form-controls/formly-toggle-input-left-aligned/formly-toggle-input-left-aligned.component";
import { FormlyCategoryViewComponent } from "../../shared/forms/form-controls/formly-category-view/formly-category-view.component";
import { FormlyTwoDecimalNumberInputComponent } from "../../shared/forms/form-controls/formly-two-decimal-number-input/formly-two-decimal-number-input.component";
import { FormlyThreeDigitDecimalInputComponent } from "../../shared/forms/form-controls/formly-three-digit-decimal-input/formly-three-digit-decimal-input.component";
import { FormlyExpenseAmountInputComponent } from "../../shared/forms/form-controls/formly-expense-amount-input/formly-expense-amount-input.component";
import { FormlyRadioDescriptionInputComponent } from "../../shared/forms/form-controls/formly-radio-description-input/formly-radio-description-input.component";
import { FormlyLogoUploaderComponent } from "../../shared/forms/form-controls/formly-logo-uploader/formly-logo-uploader.component";
import { FormlyCalendarDateInputComponent } from "../../shared/forms/form-controls/formly-calendar-date-input/formly-calendar-date-input.component";
import { FormlyLocationInputComponent } from "../../shared/forms/form-controls/formly-location-input/formly-location-input.component";
import { FormlyMileageDetailsComponent } from "../../shared/forms/form-controls/formly-mileage-details/formly-mileage-details.component";
import { FormlyNrDisplayComponent } from "../../shared/forms/form-controls/formly-nr-display/formly-nr-display.component";
import { FormlyGooglePurchaseComponent } from "../../shared/forms/form-controls/formly-google-purchase/formly-google-purchase.component";
import { FormlyLinkComponentComponent } from "../../shared/forms/form-controls/formly-link-component/formly-link-component.component";
import { SelectVehiclePanelComponent } from "../../shared/forms/selection-panels/select-vehicle-panel/select-vehicle-panel.component";
import { FormlyRadioInputWithSideDescriptionComponent } from "../../shared/forms/form-controls/formly-radio-input-with-side-description/formly-radio-input-with-side-description.component";
import { MultipleLegsPanelComponent } from "src/app/shared/forms/selection-panels/multiple-legs-panel/multiple-legs-panel.component";
import { FormlyToggleRuleComponent } from "../../shared/forms/form-controls/formly-toggle-rule/formly-toggle-rule.component";
import { AddjourneyPanelComponent } from "src/app/shared/forms/selection-panels/add-journey-panel/add-journey-panel.component";
import { FormlyGoogleLocationInputComponent } from "src/app/shared/forms/form-controls/formly-google-location-input/formly-google-location-input.component";
import { FormlyTextWithToggleComponent } from "src/app/shared/forms/form-controls/formly-text-with-toggle/formly-text-with-toggle.component";
import { FormlyImgUploadComponent } from "../../shared/forms/form-controls/formly-img-upload/formly-img-upload.component";

import { FormlySixDigitBeforeAfterDecimalInputComponent } from "../../shared/forms/form-controls/formly-six-digit-before-after-decimal-input/formly-six-digit-before-after-decimal-input.component";
import { FormlyDynamicRadioInputComponent } from "../../shared/forms/form-controls/formly-dynamic-radio-input/formly-dynamic-radio-input.component";
import { NRFCApprovalPanelComponent } from "src/app/two-step-approval/nrfc-approval-panel/nrfc-approval-panel.component";
import { FormlyImageUploader2Component } from "../../shared/forms/form-controls/formly-image-uploader2/formly-image-uploader2.component";
import { FormlyImageUploaderComponent } from "../../shared/forms/form-controls/formly-image-uploader/formly-image-uploader.component";
import { OfficeAddressPanelComponent } from "../../claimant-on-web/office-address-panel/office-address-panel.component";
import {FormlyLeftTextWithToggleComponent} from "src/app/shared/forms/form-controls/formly-left-text-with-toggle/formly-left-text-with-toggle.component";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import {FormlyPlusButtonComponent} from "src/app/shared/forms/form-controls/formly-plus-button/formly-plus-button.component";
import {FormlyDateFormatComponent} from "src/app/shared/forms/form-controls/formly-date-format/formly-date-format.component";
import {GenericPolicyViolationPanelComponent} from "src/app/manage-category/generic-policy-violation-panel/generic-policy-violation-panel.component"
import { SubCategoryExpenseDetailsPanelComponent } from 'src/app/manage-category/sub-category-expense-details-panel/sub-category-expense-details-panel.component';
import { SpecialCategoryExpenseItemisationPanelComponent } from '../../claimant-on-web/expense/special-category-expense-itemisation-panel/special-category-expense-itemisation-panel.component';
import {MultipleTabPolicyViolationScreenComponent} from 'src/app/manage-category/multiple-tab-policy-violation-screen/multiple-tab-policy-violation-screen.component'


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: {
        checkExpressionOn: "changeDetectionCheck",
        lazyRender: false, 
        resetFieldOnHide: false,  
      },
      types: [
        { name: "input-panel", component: FormlyFieldInputPanelComponent },
        { name: "calendar-input", component: FormlyCalendarInputComponent },
        { name: "number-input", component: FormlyNumberInputComponent },
        { name: "integer-input", component: FormlyIntegerInputComponent },
        { name: "textarea-input", component: FormlyTextareaInputComponent },
        { name: "disabled-input", component: FormlyDisabledInputComponent },
        { name: "text-input", component: FormlyTextInputComponent },
        { name: "tag-input", component: FormlyTagInputComponent },
        {
          name: "advance-balance-input",
          component: FormlyAdvanceBalanceInputComponent,
        },
        {
          name: "ng-select-input",
          component: FormlyNgSelectInputComponent,
          wrappers: ["form-field"],
        },
        { name: "formly-link", component: FormlyLinkComponentComponent },
        {
          name: "ng-select-description-input",
          component: FormlyNgSelectDescriptionInputComponent,
          wrappers: ["form-field"],
        },
        { name: "list-input", component: FormlyUlComponent },
        { name: "radio-button", component: FormlyRadioButtonComponent },
        { name: "vehicle-button", component: FormlyVehicleButtonComponent },
        { name: "checkbox-input", component: FormlyCheckboxInputComponent },
        { name: "formly-receipt", component: FormlyReceiptComponent },
        { name: "formly-justification", component: FormlyNotesComponent },
        {
          name: "formly-advance-policy",
          component: FormlyAdvancePolicyComponent,
        },
        {
          name: "formly-policy-violation",
          component: FormlyPolicyViolationComponent,
        },
        {
          name: "formly-advance-policy-violation",
          component: FormlyAdvancePolicyViolationComponent,
        },
        { name: "formly-toggle-input", component: FormlyToggleInputComponent },
        { name: "radio-input", component: FormlyRadioInputComponent },
        {
          name: "ng-multi-select-input",
          component: FormlyNgMultiSelectInputComponent,
        },
        {
          name: "currency-image-upload",
          component: FormlyCurrencyImageUploderComponent,
        },
        { name: "formly-button", component: FormlyButtonComponent },
        {
          name: "calendar-date-month-input",
          component: FormlyCalendarDateMonthInputComponent,
        },
        {
          name: "formly-toggle-input-left-aligned",
          component: FormlyToggleInputLeftAlignedComponent,
        },
        {
          name: "formly-category-view",
          component: FormlyCategoryViewComponent,
        },
        {
          name: "radio-side-description-input",
          component: FormlyRadioInputWithSideDescriptionComponent,
        },
        {
          name: "radio-description-input",
          component: FormlyRadioDescriptionInputComponent,
        },
        {
          name: "two-decimal-number-input",
          component: FormlyTwoDecimalNumberInputComponent,
        },
        {
          name: "three-digit-decimal-input",
          component: FormlyThreeDigitDecimalInputComponent,
        },
        {
          name: "formly-expense-amount-input",
          component: FormlyExpenseAmountInputComponent,
        },
        { name: "logo-uploader", component: FormlyLogoUploaderComponent },
        { name: "image-uploader", component: FormlyImageUploaderComponent },
        { name: "image-uploader2", component: FormlyImageUploader2Component },
        {
          name: "calendar-date-input",
          component: FormlyCalendarDateInputComponent,
        },
        {
          name: "formly-location-input",
          component: FormlyLocationInputComponent,
        },
        { name: "mileage-details", component: FormlyMileageDetailsComponent },
        { name: "nr-display", component: FormlyNrDisplayComponent },
        { name: "google-purchase", component: FormlyGooglePurchaseComponent },
        {
          name: "google-location-input",
          component: FormlyGoogleLocationInputComponent,
        },
        { name: "toggle-rule", component: FormlyToggleRuleComponent },
        {
          name: "tex-with-toggle-input",
          component: FormlyTextWithToggleComponent,
        },
        {
          name: "six-digit-before-after-decimal-input",
          component: FormlySixDigitBeforeAfterDecimalInputComponent,
        },
        { name: "img-upload", component: FormlyImgUploadComponent },
        {
          name: "radio-dynamic-input",
          component: FormlyDynamicRadioInputComponent,
        },
        {
          name:'formly-left-text-with-toggle',
          component:FormlyLeftTextWithToggleComponent
        },
        {
          name:'formly-plus-button',
          component:FormlyPlusButtonComponent
        },
        {
          name: 'formly-date-format',
          component: FormlyDateFormatComponent
        }
      ],
      validators: [
        { name: "invalidEmail", validation: EmailValidator },
        { name: "whitespace", validation: NoWhitespaceValidator },
      ],
    }),
    FormlyBootstrapModule,
    NgbModule,
    NgbTooltipModule,
    NgSelectModule,
    TranslateModule,
    NgxTrimDirectiveModule,
    SharedModule,
    GridModule
  ],
  declarations: [
    DynamicFormComponent,
    FormlyFieldInputPanelComponent,
    FormlyCalendarInputComponent,
    FormlyNumberInputComponent,
    FormlyDisabledInputComponent,
    FormlyIntegerInputComponent,
    FormlyTextareaInputComponent,
    FormlyTextInputComponent,
    FormlyNgSelectInputComponent,
    FormlyNgSelectDescriptionInputComponent,
    FormlyTagInputComponent,
    FormlyAdvanceBalanceInputComponent,
    SelectCategoryPanelComponent,
    ViewExpensePolicyPanelComponent,
    SelectPreApprovalTagPanelComponent,
    SelectCustomerPanelComponent,
    SelectCostCentrePanelComponent,
    SelectProjectPanelComponent,
    SelectBusinessPurposePanelComponent,
    SelectVehiclePanelComponent,
    SelectReceiptTypePanelComponent,
    CreateDetourPanelComponent,
    SelectStatePanelComponent,
    AssociateCreditCardExpensePanelComponent,
    SelectAdditionalFieldDataPanelComponent,
    CreateTagPanelComponent,
    SelectRecentJourneysPanelComponent,
    AdvancePassbookPanelComponent,
    PolicyViolationPanelComponent,
    AdvancePolicyViolationPanelComponent,
    FormlyUlComponent,
    FormlyRadioButtonComponent,
    FormlyVehicleButtonComponent,
    FormlyCheckboxInputComponent,
    FormlyReceiptComponent,
    FormlyNotesComponent,
    FormlyAdvancePolicyComponent,
    FormlyPolicyViolationComponent,
    FormlyAdvancePolicyViolationComponent,
    FormlyToggleInputComponent,
    FormlyRadioInputComponent,
    ActionPanelComponent,
    FormlyNgMultiSelectInputComponent,
    FormlyCurrencyImageUploderComponent,
    FormlyButtonComponent,
    FormlyCalendarDateMonthInputComponent,
    FormlyToggleInputLeftAlignedComponent,
    FormlyCategoryViewComponent,
    FormlyRadioDescriptionInputComponent,
    FormlyTwoDecimalNumberInputComponent,
    FormlyThreeDigitDecimalInputComponent,
    FormlyExpenseAmountInputComponent,
    FormlyLogoUploaderComponent,
    FormlyCalendarDateInputComponent,
    FormlyLocationInputComponent,
    FormlyMileageDetailsComponent,
    FormlyNrDisplayComponent,
    FormlyGooglePurchaseComponent,
    FormlyLinkComponentComponent,
    MultipleLegsPanelComponent,
    FormlyToggleRuleComponent,
    AddjourneyPanelComponent,
    FormlyGoogleLocationInputComponent,
    FormlyTextWithToggleComponent,
    FormlySixDigitBeforeAfterDecimalInputComponent,
    FormlyDynamicRadioInputComponent,
    FormlyImgUploadComponent,
    NRFCApprovalPanelComponent,
    FormlyImageUploader2Component,
    FormlyImageUploaderComponent,
    OfficeAddressPanelComponent,
    FormlyLeftTextWithToggleComponent,
    FormlyPlusButtonComponent,
    FormlyDateFormatComponent,
    GenericPolicyViolationPanelComponent,
    SubCategoryExpenseDetailsPanelComponent,
    SpecialCategoryExpenseItemisationPanelComponent,
    MultipleTabPolicyViolationScreenComponent
  ],
  providers: [],
  exports: [
    NgbTooltipModule,
    DynamicFormComponent,
    ActionPanelComponent,
    PolicyViolationPanelComponent,
    AdvancePolicyViolationPanelComponent,
    MultipleLegsPanelComponent,
    AddjourneyPanelComponent,
    NRFCApprovalPanelComponent,
    ViewExpensePolicyPanelComponent,
    FormlyPlusButtonComponent,
    GenericPolicyViolationPanelComponent,
    SubCategoryExpenseDetailsPanelComponent,
    SpecialCategoryExpenseItemisationPanelComponent,
    MultipleTabPolicyViolationScreenComponent
  ],
})
export class DynamicFormsModule {
  constructor() {}
}
