import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Claims } from '../../../../_models/common/claims';
import { ClaimsService } from '../../../../_services/common/claims.service';

@Component({
  selector: 'app-policy-name-renderer',
  templateUrl: './policy-name-renderer.component.html',
})
export class PolicyNameRendererComponent implements ICellRendererAngularComp {
  public isOtherPolicyDisplay: boolean = false;
  public isPolicyIconDisplay: boolean = false;
  public isPotentialFraudDisplay: boolean = false;
  public isPolicyViolated: boolean = false;
  public potentialFraud: boolean = false;
  public policyArray: string[] = [];
  public claimantName: string = '';
  public approverAsDeputy: boolean = false;
  public approverAsCA:boolean = false;
  public actualApproverName: string = '';
  violationTooltip: string;
  breachTooltip: string;
  claims: Claims;
  isExpenseEditedByFM: boolean = false;
  isExpenseEditedByFMIconDisplay:  boolean= false;

  constructor(private translate: TranslateService,
    private claimsService: ClaimsService) {
    this.claimsService.currentClaims.subscribe(claims => this.claims = claims);
  }

  initilize(params: ICellRendererParams) {
    if (params.colDef && params.colDef.headerComponentParams) {
      this.isOtherPolicyDisplay = params.colDef.headerComponentParams.isOtherPolicyDisplay;
      this.isPolicyIconDisplay = params.colDef.headerComponentParams.isPolicyIconDisplay;
      this.isPotentialFraudDisplay = params.colDef.headerComponentParams.isPotentialFraudDisplay;
      this.isExpenseEditedByFMIconDisplay = params.colDef.headerComponentParams.isExpenseEditedByFMIconDisplay;
    }
    if (params.data) {
      this.isPolicyViolated = params.data.isPolicyViolated || (params.data.potentialFraud && this.isPotentialFraudDisplay);
      this.potentialFraud = params.data.potentialFraud;
      this.policyArray = params.data.policyArray;
      this.claimantName = params.data.claimantName;
      this.approverAsDeputy = params.data.approverAsDeputy;
      this.approverAsCA = params.data.approverAsCounterApprover;
      this.actualApproverName = params.data.actualApproverName != null ? params.data.actualApproverName.trim() : '';
      this.isExpenseEditedByFM = params.data.isExpenseEditedByFM;
      

      if(params.data.advanceId || params.data.advancesCount) {
        this.violationTooltip = this.translate.instant('advance_policy_violation');
        this.breachTooltip = this.translate.instant('advance_policy_breach');
      }
      else {
        if(params.data.isPolicyViolated && !this.potentialFraud)
           this.violationTooltip = this.translate.instant('expense_policy_violation');
        if(this.potentialFraud && !params.data.isPolicyViolated)
           this.violationTooltip = this.translate.instant('potential_fraud');
        if(this.potentialFraud && params.data.isPolicyViolated)
           this.violationTooltip = this.isPotentialFraudDisplay ?
            this.translate.instant('policy_violation_potential_fraud') :
            this.translate.instant('expense_policy_violation');

        this.breachTooltip = this.translate.instant('expense_policy_breach');
      }
    }
  }

  // called on init
  agInit(params: any): void {
    this.initilize(params);
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.initilize(params);
    return true;
  }

}
