export class Claims {
  UserId: number;
  LoginId: string;
  Token: string;
  OrgId: number;
  EmailId: string;
  FMRoleType: number;
  CurrentOrganisationCurrency: string;
  CurrentOrganisationCurrencyCode: string;
  FinanceManager: string;
  Password: string;
  OrgLogo: string = "assets/images/logo.png";
  SelectedCurrency: string;
  SelectedCurrencyCode: string;
  OrgName: string;
  CurrencyIcon: string;
  CurrencyCode: string;
  EmployeesInvited: number;
  ApproverName: string;
  OrganisationBasicSetup: string;
  IsFinanceManager: boolean = false;
  TwoStepApproval: boolean = false;
  MenuSettings: boolean = false;
  expensesPendingPayment: boolean = false;
  IsCardSetupComplete: boolean;
  pendingForFinanceApproval: number = 0;
  pendingAllForPayment: number = 0;
  pendingExpensesForPayment: number = 0;
  AccountingCode: string;
  accountingSystemId: number = 0;
  accountingSystemType: number = 0;
  CartCount: number = 0;
  CurrentOrganisationCurrencyId: number;
  IsRedirectFromCart: boolean = false;
  IsOnboardingCompleted: boolean = true;
  IsAccountingIntegrationPackagePurchasedOrTrial: boolean = false;
  IsAttendeeTypePurchased: boolean = false;
  FirstName: string;
  LastName: string;
  IsCreditCardPackageSubscribed: boolean = false;
  IsPackagePurchasedOrTrial: boolean = false;
  showGooglePackagePurchaseReminder: boolean = false;
  googlePackagePurchaseReminderThreshold: number = 0;
  IsSocialMediaUser: boolean = false;
  IsMarqueeOn: boolean = false;
  MarqueeText: string;
  IsCostCentrePackagePurchasedOrTrial: boolean = false;
  IsOrgBasicFunctionTrialEndsReminder: boolean = false;
  IsOrgBasicFunctionTrialEndsAlert: boolean = false;
  IsCustomerPackagePurchasedOrTrial: boolean = false;
  IsExportReportPackagePurchasedOrTrial: boolean = false;
  IsExpenseCategoryPackagePurchasedOrTrial: boolean = false;
  IsTaxPackagePurchasedOrTrial: boolean = false;
  ReferAndEarnRole: number;
  IsMultipleCompaniesPackagePurchasedOrTrial: boolean = false;
  IsProjectBudgetPurchasedOrTrial: boolean = false;
  pendingCountNRFinanceApproval: number = 0;
  pendingCountNRApprover: number = 0;
  pendingExpenseCountApprover: number = 0;
  isNRPackagePurchasedOrTrial: boolean = false;
  ShowPendingDeputyPanel: boolean = true;
  isFCPackagePurchasedOrTrial: boolean = false;
  pendingCountFCFinanceApproval: number = 0;
  pendingCountFCApprover: number = 0;
  ShowPendingEmployeeWithGradePanel: boolean = true;
  CompanyApproverRule: number = 0;
  IsLocationPurchasedOrTrial: boolean = false;
  isClaimOnWebAllowed: boolean = false;
  RateType: number = 0;
  IsSecondaryFMPackagePurchasedOrTrial: boolean = false;
  IsAssignDeputyToApproversPurchased: boolean = false;
  IsClaimantWiseMonthlyCappingEnabled: boolean = false;
  IsClaimantWiseYearlyCappingEnabled: boolean = false;
  IsSupplierPurchasedOrTrial: boolean = false;
  IsDocPackagePurchasedOrTrial: boolean = false;
  IsLeaveManagementAllowed: boolean = false;
  ApprovalRuleSetting: number;
  showCostCentresWithoutApproverCount: boolean = false;
  isUniversalImportAllowed: boolean = false;
  IsMultipleCountryPackagePurchasedOrTrial: boolean = false;
  IsDuplicateCheckerPurchasedOrTrial: boolean = false;
  SelectedCountry: number;
  SelectedCountryName: string;
  UserHomeCurrencyId: number;
  orgRoleId: number = 0;
  isPreApprovalPurchased: boolean = false;
  pendingCountPreApproval: number = 0;
  pendingExpensesWithApprover: number = 0;
  UserHomeCurrencyCode: string;
  isAdvanceBalanceOutstanding: boolean = false;
  isAdvancesFunctionPurchasedOrTrial: boolean = false;
  IsCashAdvanceEnabled: boolean = false;
  IsCreditCardAdvanceEnabled: boolean = false;
  advanceCappingPolicyType: number = 0;
  pendingAdvancesForApproval: number = 0;
  pendingAdvancesForPayment: number = 0;
  pendingAdvanceForFinanceApproval: number = 0;
  AccIntSelectedCompanyData: any;
  isMultiAccSysIntAllow: boolean = false;
  isPolicyOnVehicleChange: boolean = false;
  triangulationRule: number;
  supplierRule: number;
  showCompliancePopup: boolean = false;
  complianceHeader: string;
  complianceStatement: string;
  showRecommendationPopup: boolean;
  isStripPaymentFailed: boolean = false;
  IsDelegator: boolean = false;
  IsDelegate: boolean = false;
  IsDelegateFunctionPurchasedOrTrial: boolean = false;
  displayAlertPanelModelForMiles: boolean;
  appVersion: number;
  userType: number;
  kycStatus: number;
  isAPAutomationFunctionPurchasedOrTrial: boolean = false;
  pendingCountInvoiceApproval: number = 0;
  pendingCountInvoiceFinanceApproval: number = 0;
  pendingCountInvoicePay: number = 0;
  IsCounterApproverPurchased: boolean = false;
  IsVendorPurchased: boolean = false;
  IsKYCMandatoryForInvoice: boolean = false;
  IsAPAutomationAllow: boolean = false;
  nrfcStatus: number = 0;
  UserHomeCurrencyName: string;
  SelectedCurrencyName: string;
  docStatus: number;
  isDocRequired: boolean;
  invoiceActiveTab: string;
  IsFromOrgLogin: boolean;
  BandId: number = 0;
  IsGetStartedPopup: boolean;
  isBasicFunctionPurchased: boolean = true;
  IsPayStepEnabled: boolean = false;
  IsLeftMenuCollapseAndStopToggle: boolean = false;
  IsRecallExportedExpensesPurchased: boolean = false;
  IsSpecialCategoryFunctionPurchasedorTrial: boolean = false;
  IsAutomaticScanEnabled : boolean = false;
  ManageCCOptions:number = 0;
}
